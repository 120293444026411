import { React, useEffect, useState } from "react";
import Input from "../../shared/components/FormElements/Input";
import {
  VALIDATOR_MAXLENGTH,
  VALIDATOR_REQUIRE,
} from "../../shared/util/validators";

import { MoreHoriz, Close } from "@mui/icons-material";

const RecipeSection = (props) => {
  const {
    setFormData,
    instructionId,
    formState,
    activeInstructionId,
    setActiveInstructionId,
  } = props;

  const [isActive, setIsActive] = useState(false);

  /*Toggle active state */
  useEffect(() => {
    if (activeInstructionId === instructionId) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [activeInstructionId]);

  return (
    /* Om aktiv så renderas active-CSS:en, annars inactive-CSS:en */
    <div
      className={
        isActive
          ? `step-by-step-instruction-active`
          : `step-by-step-instruction-inactive`
      }
    >
      <p
        onClick={() => {
          if (
            window.confirm(
              "Är du säker på att du vill radera rubriken? OBS! Alla steg efter denna rubrik kommer att finnas kvar."
            )
          ) {
            props.deleteInstructionHandler(instructionId);
          }
        }}
        className="delete-instruction"
      >
        Radera rubrik
      </p>

      <div className="input-text-and-icon-container">
        <Input
          style={`${
            isActive
              ? "description-input-active"
              : "description-input-inactive description-input-inactive-section"
          }`}
          formState={props.formState}
          setFormData={props.setFormData}
          element="input"
          type="text"
          placeholder="Kort beskrivande rubrik av kommande steg"
          label={"Rubrik"}
          validators={[VALIDATOR_REQUIRE(), VALIDATOR_MAXLENGTH(40)]}
          maxInput="40"
          errorText=""
          onInput={props.inputHandler}
          charCounter={true}
          instructionId={props.instructionId}
          initialValue={props.value}
        />
        {!isActive && (
          <div className="inactive-text-and-icon-container">
            <div className="step-by-step-inactive_text">{props.value}</div>
            <div className="step-by-step_inactive_more-icon">
              <MoreHoriz
                className={`recipe-more-icon ${
                  !formState.isValid && activeInstructionId !== null
                    ? `recipe-more-icon-hide`
                    : null
                }`}
                onClick={() => {
                  setActiveInstructionId(instructionId);
                }}
              />
            </div>
          </div>
        )}
      </div>

      {/* Done button */}
      {isActive && (
        <div className="ingredient-buttons ">
          <div></div>
          <button
            onClick={() => {
              setActiveInstructionId(null);
            }}
            type="submit"
            className="button-ingredient-done button-ingredient-done-section"
            disabled={!formState.isValid}
          >
            Klar
          </button>
        </div>
      )}
    </div>
  );
};

export default RecipeSection;
