import React from "react";
import { Typewriter } from "react-simple-typewriter";

import "./TypeWriter.css";

const TypeWriter = () => {
  return (
    <div className="typewriter-container">
      <h1>
        <span className="highlight">Vi dämpar kurret </span>
        <br />
        <Typewriter
          words={[
            "inom kort!",
            "snart!",
            "strax!",
            "sooon!",
            "om ett tag!",
            "in a twinkling!",
          ]}
          loop={false}
          cursor
          cursorStyle="⎮"
          typeSpeed={80}
          deleteSpeed={50}
          delaySpeed={1500}
        />
      </h1>
    </div>
  );
};

export default TypeWriter;
