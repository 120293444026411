import React from "react";
import { Steps } from "rsuite";
import { useHistory, useParams } from "react-router-dom";


import "./RecipeUploadProgressBar.css";

// skickar med vilket steg man står i och beroende på detta så fylls baren i
// props.step = 2
const RecipeUploadProgressBar = (props) => {
  const history = useHistory();
  const recipeId = useParams().rid; // gets the recipe-id located in the url


  return (
    <div>
      {props.currentStep === null && (
        <div className="progress-bar" style={{visibility: "hidden"}}>
          <div className={`progress-bar__ball ball--active`}>1</div>
          <div className={`progress-bar__line line--inactive`}>
            <div className="line--inprogress"></div>
          </div>
          <div className={`progress-bar__ball ball--inactive`}>2</div>
          <div className={`progress-bar__line line--inactive`}></div>
          <div className={`progress-bar__ball ball--inactive`}>3</div>
          <div className={`progress-bar__line line--inactive`}></div>
          <div className={`progress-bar__ball ball--inactive`}>4</div>
        </div>
      )}


      {props.currentStep === 1 && (
        <div className="progress-bar">
          <div className={`progress-bar__ball ball--active`}>1</div>
          <div className={`progress-bar__line line--inactive`}>
            <div className="line--inprogress"></div>
          </div>
          <div className={`progress-bar__ball ball--inactive`}>2</div>
          <div className={`progress-bar__line line--inactive`}></div>
          <div className={`progress-bar__ball ball--inactive`}>3</div>
          <div className={`progress-bar__line line--inactive`}></div>
          <div className={`progress-bar__ball ball--inactive`}>4</div>
        </div>
      )}

      {props.currentStep === 2 && (
        <div className="progress-bar">
          <div className={`progress-bar__ball ball--active`} onClick={() => { history.push(`/recipe/${recipeId}/step-1`); }}>1</div>
          <div className={`progress-bar__line line--active`}></div>
          <div className={`progress-bar__ball ball--active`}>2</div>
          <div className={`progress-bar__line line--inactive`}>
            <div className="line--inprogress"></div>
          </div>
          <div className={`progress-bar__ball ball--inactive`}>3</div>
          <div className={`progress-bar__line line--inactive`}></div>
          <div className={`progress-bar__ball ball--inactive`}>4</div>
        </div>
      )}
      {props.currentStep === 3 && (
        <div className="progress-bar">
          <div className={`progress-bar__ball ball--active`} onClick={() => { history.push(`/recipe/${recipeId}/step-1`); }}>1</div>
          <div className={`progress-bar__line line--active`}></div>
          <div className={`progress-bar__ball ball--active`} onClick={() => { history.push(`/recipe/${recipeId}/step-2`); }}>2</div>
          <div className={`progress-bar__line line--active`}></div>
          <div className={`progress-bar__ball ball--active`}>3</div>
          <div className={`progress-bar__line line--inactive`}>
            <div className="line--inprogress"></div>
          </div>
          <div className={`progress-bar__ball ball--inactive`}>4</div>
        </div>
      )}
      {props.currentStep === 4 && (
        <div className="progress-bar">
          <div className={`progress-bar__ball ball--active`} onClick={() => { history.push(`/recipe/${recipeId}/step-1`); }}>1</div>
          <div className={`progress-bar__line line--active`}></div>
          <div className={`progress-bar__ball ball--active`} onClick={() => { history.push(`/recipe/${recipeId}/step-2`); }}>2</div>
          <div className={`progress-bar__line line--active`}></div>
          <div className={`progress-bar__ball ball--active`} onClick={() => { history.push(`/recipe/${recipeId}/step-3`); }}>3</div>
          <div className={`progress-bar__line line--active`}></div>
          <div className={`progress-bar__ball ball--active`}>4</div>
        </div>
      )}
    </div>
  );
};

export default RecipeUploadProgressBar;