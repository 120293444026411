import React, { Fragment, useContext, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { AuthContext } from "../../shared/context/auth-context";
import { useHttpClient } from "../../shared/hooks/http-hook";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import Card from "../../shared/components/UIElements/Card";
import Button from "../../shared/components/FormElements/Button";

import "./Verify.css";

const Verify = () => {
  const auth = useContext(AuthContext);
  const history = useHistory();
  const userId = useParams().uid;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [value, setValue] = useState("");

  const onChangeHandler = (event) => {
    event.preventDefault();
    setValue(event.target.value);
  };

  const verifyHandler = async () => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/api/users/verify",
        "POST",
        JSON.stringify({
          userId: userId,
          verificationCode: value,
        }),
        {
          "Content-Type": "application/json",
        }
      );

      // authcontext login
      auth.login(responseData.token);
    } catch (err) {}
  };

  return (
    <Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <Card className="authentication">
        <div className="form-control">
          <label htmlFor="verify-code">Verifieringskod (mailad)</label>
          <input
            element="input"
            id="verify-code"
            type="text"
            placeholder=""
            label="Verifieringskod"
            onChange={onChangeHandler}
            value={value}
          />
        </div>
        <Button disabled={value.length !== 4} onClick={verifyHandler} center>
          Verifiera
        </Button>
      </Card>
    </Fragment>
  );
};

export default Verify;
