import React from "react";
import { Link } from "react-router-dom";

import "./Button.css";

const Button = (props) => {
  if (props.href) {
    return (
      <div className={props.center && "button--center"}>
        <a
          className={`button button--${props.size || "default"} ${
            props.inverse && "button--inverse"
          } ${props.danger && "button--danger"}`}
          href={props.href}
        >
          {props.children}
        </a>
      </div>
    );
  }
  if (props.to) {
    return (
      <div className={props.center && "button--center"}>
        <Link
          to={props.to}
          exact={props.exact}
          className={`button button--${props.size || "default"} ${
            props.inverse && "button--inverse"
          } ${props.danger && "button--danger"}`}
        >
          {props.children}
        </Link>
      </div>
    );
  }
  if (props.fileUpload) {
    return (
      <button
        className="button__add-file"
        type={props.type}
        onClick={props.onClick}
        disabled={props.disabled}
      >
        {props.children}
      </button>
    );
  }
  return (
    <div className={props.center && "button--center"}>
      <button
        className={`button button--${props.size || "default"} ${
          props.inverse && "button--inverse"
        } ${props.danger && "button--danger"}`}
        type={props.type}
        onClick={props.onClick}
        disabled={props.disabled}
      >
        {props.children}
      </button>
    </div>
  );
};

export default Button;
