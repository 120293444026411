import React from "react";
import { Link } from "react-router-dom";

import exitLogo from "../../shared/assets/exit.svg";
import "./Menu.css";

const Menu = () => {
  return (
    <div className="Menu-container">
      <div className="Menu-container__exit">
        {/*}
        <Link to="/">
          <img src={exitLogo} alt="exit" />
        </Link>
  */}
      </div>
      <div className="Menu-container__text">
        <h1 onClick={() => (window.location = "mailto:macaroni@kurr.co")}>
          niklas@kurr.co
        </h1>
        <h4 onClick={() => (window.location = "tel:0738126762")}>
          +46 (0) 73 812 67 62
        </h4>
      </div>
    </div>
  );
};

export default Menu;
