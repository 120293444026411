/**
 * This file contains a more simplified version of the Input.js component,
 * specifically designed for handling dropdown/select inputs.
 */
import React from "react";
import "./Input.css"; // Assume using the same CSS style
import "./Dropdown.css"; // Assume using the same CSS style

const Dropdown = ({ id, label, value, onChange, options }) => {
  return (
    <div className="form-control font">
      <div className="dropdown-container">
        <p>{label}</p>
        <select id={id} value={value} onChange={onChange}>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default Dropdown;
