import React from "react";
import { Warning, AutoAwesome } from "@mui/icons-material";

import RecipeItem from "./RecipeItem";
import "./RecipesList.css";

const RecipesList = (props) => {
  const nonPublicRecipes = props.items.filter((recipe) => !recipe.public);
  const publicRecipes = props.items.filter((recipe) => recipe.public);

  return (
    <div>
      <div
        className="recipe-summary-item__recipe--incomplete"
        style={{ fontStyle: "normal", fontWeight: "400" }}
      >
        <p>
          <AutoAwesome
            style={{
              color: `${"var(--ios-yellow)"}`,
              fontSize: "16px",
              marginRight: "4px",
              position: "relative",
              top: "3px",
            }}
          />
          = Autouppladdadt recept.{" "}
          <span style={{ fontWeight: 600 }}>
            Klicka på ikonen för att se mer info.
          </span>
        </p>
        <p>
          <Warning
            style={{
              color: `${"var(--ios-yellow)"}`,
              fontSize: "16px",
              marginRight: "4px",
              position: "relative",
              top: "3px",
            }}
          />
          eller{" "}
          <Warning
            style={{
              color: `${"var(--ios-red)"}`,
              fontSize: "16px",
              marginRight: "4px",
              position: "relative",
              top: "3px",
            }}
          />
          = Det finns varningar i receptet. Om gul så bör du kontrollera
          varningen, men du kan dölja den. Om röd så måste du hantera varningen
          för att kunna publicera. Ingen symbol? Inga varningar.{" "}
          <span style={{ fontWeight: 600 }}>
            Klicka på ikonen för att se vad som varnar.
          </span>
        </p>
      </div>
      <p className="recipe-header" style={{ paddingTop: "16px" }}>
        Opublicerade Recept
      </p>
      <p
        className="recipe-summary-item__recipe--incomplete"
        style={{ fontStyle: "normal", fontWeight: "400" }}
      >
        Dessa recept är <span style={{ fontStyle: "italic" }}>inte</span>{" "}
        synliga i appen just nu. Publicera-knappen är klickbar när recept är
        komplett och fritt från röda varningar.
      </p>
      {nonPublicRecipes.length > 0 ? (
        <>
          <ul className="recipes-summary-list-non-public">
            {nonPublicRecipes.map((recipe, index) => (
              <RecipeItem
                key={recipe._id}
                id={recipe._id}
                label={index + 1 + "."}
                title={recipe.title}
                complete={recipe.complete}
                warnings={recipe.autoUpload.warnings.current}
                hiddenWarnings={recipe.autoUpload.warnings.hidden}
                isAdmin={props.isAdmin}
                creator={recipe.creator.username}
                createdAt={recipe.createdAt}
                public={recipe.public}
                isAutoUploaded={recipe.autoUpload?.isAutoUploaded}
                unitCorrections={recipe.autoUpload?.feedback?.unitCorrections}
                sourceUrl={recipe?.autoUpload?.sourceUrl}
                deepLinkUrl={`https://kurr.co/recipe/?recipeId=${recipe._id}&senderId=${recipe.creator._id}`}
              />
            ))}
          </ul>
          <hr className="recipe-summary-item__line" />
        </>
      ) : (
        <div className="no-recipes">
          <p>Det finns inga opublicerade recept.</p>
        </div>
      )}

      <p className="recipe-header">Publicerade Recept</p>
      <p
        className="recipe-summary-item__recipe--incomplete"
        style={{ fontStyle: "normal", fontWeight: "400" }}
      >
        Dessa recept är synliga i appen just nu.
      </p>
      {publicRecipes.length > 0 ? (
        <>
          <ul className="recipes-summary-list-public">
            {publicRecipes.map((recipe, index) => (
              <RecipeItem
                key={recipe._id}
                id={recipe._id}
                label={index + 1 + "."}
                title={recipe.title}
                complete={recipe.complete}
                warnings={recipe.autoUpload.warnings.current}
                hiddenWarnings={recipe.autoUpload.warnings.hidden}
                isAdmin={props.isAdmin}
                creator={recipe.creator.username}
                createdAt={recipe.createdAt}
                public={recipe.public}
                isAutoUploaded={recipe.autoUpload?.isAutoUploaded}
                unitCorrections={recipe.autoUpload?.feedback?.unitCorrections}
                sourceUrl={recipe?.autoUpload?.sourceUrl}
                deepLinkUrl={`https://kurr.co/recipe/?recipeId=${recipe._id}&senderId=${recipe.creator._id}`}
              />
            ))}
            <hr className="recipe-summary-item__line" />
          </ul>
        </>
      ) : (
        <div className="no-recipes">
          <p>Det finns inga publicerade recept.</p>
        </div>
      )}
    </div>
  );
};

export default RecipesList;
