import React, { useState, useEffect } from "react";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { MoreHoriz, ContentCopy, Share } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { Warning, AutoAwesome } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import "./RecipeItem.css";
import { Alert } from "@mui/material";

const RecipeItem = (props) => {
  const [missingIngredientDbId, setMissingIngredientDbId] = useState(false);
  const [warningsTooltipOpen, setwarningsTooltipOpen] = useState(false);
  const [creatorWarnings, setCreatorWarnings] = useState([]);
  const [autoUploadTooltipOpen, setAutoUploadTooltipOpen] = useState(false);
  const [isPublic, setIsPublic] = useState(props.public);
  const [justPublished, setJustPublished] = useState(false);
  const [shareLinkText, setShareLinkText] = useState("Delningslänk");

  const { sendRequest, isLoading } = useHttpClient();

  const complete = props.complete ? "" : " Ej komplett";
  const {
    warnings,
    hiddenWarnings,
    isAdmin,
    creator,
    isAutoUploaded,
    unitCorrections,
    sourceUrl,
  } = props;

  const NUMBER_OF_DAYS = 30;
  const isNew =
    new Date() - new Date(props.createdAt) <
    NUMBER_OF_DAYS * 24 * 60 * 60 * 1000;
  const formattedDate = isNew
    ? new Date(props.createdAt).toISOString().split("T")[0]
    : null;

  // Filter out warnings that are adminOnly or hidden and set creatorWarnings
  useEffect(() => {
    // TODO: WEB-39: Remove this "isAutoUploaded" if-statement when auto-upload frenzy is over
    if (isAutoUploaded) {
      const filteredWarnings = warnings.filter((warning) => {
        const isAdminOnly = warning.adminOnly === true;

        const isInHiddenWarnings = hiddenWarnings.some((hiddenWarning) => {
          const match =
            hiddenWarning.propName === warning.propName &&
            hiddenWarning.value === warning.value &&
            hiddenWarning.message === warning.message;
          return match;
        });

        return !isAdminOnly && !isInHiddenWarnings;
      });

      setCreatorWarnings(filteredWarnings);
    }
  }, [warnings, hiddenWarnings, isAutoUploaded]);

  useEffect(() => {
    let shouldWarn = false;
    if (warnings.length > 0) {
      warnings.forEach((warning) => {
        if (warning.message === "ingredientDbId is null or undefined") {
          shouldWarn = true;
        }
        setMissingIngredientDbId(shouldWarn);
      });
    }
  }, [warnings]);

  const handleWarningsTooltipToggle = () => {
    setwarningsTooltipOpen(!warningsTooltipOpen);
  };

  const handleautoUploadTooltipToggle = () => {
    setAutoUploadTooltipOpen(!autoUploadTooltipOpen);
  };

  const getWarningIconColor = () => {
    const warningsToCheck = isAdmin ? warnings : creatorWarnings;

    return warningsToCheck.some((warning) => warning.level === "ERROR")
      ? `${"var(--ios-red)"}`
      : `${"var(--ios-yellow)"}`;
  };

  const formatValue = (value) => {
    if (value === null) return "null";
    if (value === undefined) return "undefined";
    if (value === "") return "''";
    if (Array.isArray(value) && value.length === 0) return "[]";
    return value;
  };

  const getWarningTextColor = (level) => {
    return level === "WARNING"
      ? `${"var(--ios-yellow-system-dark)"}`
      : "#FF9D98";
  };

  const renderCreatorWarningsList = () => {
    const handleHideClick = async (warning) => {
      try {
        const confirmed = window.confirm(
          "Är du säker på att du dölja varningen?"
        );
        if (!confirmed) return;

        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/api/recipes/hide-warning`,
          "POST",
          JSON.stringify({ recipeId: props.id, warningId: warning._id }),
          { "Content-Type": "application/json" }
        );

        if (responseData.message === "Warning hidden.") {
          setCreatorWarnings(
            creatorWarnings.filter((w) => w._id !== warning._id)
          );
        }
      } catch (err) {
        alert(
          `Något gick fel! Det gick inte att dölja varningen. Försök igen senare.`
        );
      }
    };

    return (
      <ul
        style={{
          padding: 8,
          paddingLeft: 12,
          margin: 4,
          listStyleType: "decimal",
        }}
      >
        {creatorWarnings.map((warning, index) => (
          <li
            key={index}
            style={{
              marginBottom: "16px",
              color: "white",
              lineHeight: "1.3",
            }}
          >
            <div
              style={{
                marginBottom: "16px",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
              }}
            >
              <div>
                <span
                  style={{
                    color: getWarningTextColor(warning.level),
                    fontWeight: "600",
                  }}
                >
                  {warning.level === "ERROR" ? "Fixa: " : "Dubbelchecka: "}
                </span>
                {warning.messageSE}
              </div>
              {warning.level === "WARNING" && (
                <button
                  onClick={() => handleHideClick(warning)}
                  className="btn-publish"
                  style={{
                    color: "white",
                    display: "inline",
                    paddingLeft: "8px",
                    fontSize: "11px",
                  }}
                >
                  Dölj
                </button>
              )}
            </div>
          </li>
        ))}
      </ul>
    );
  };

  const renderWarningsList = () => {
    return (
      <ul
        style={{
          padding: 0,
          paddingLeft: 8,
          margin: 4,
          listStyleType: "decimal",
        }}
      >
        {warnings.map((warning, index) => (
          <li
            key={index}
            style={{
              marginBottom: "10px",
              color: getWarningTextColor(warning.level),
            }}
          >
            <div style={{ marginBottom: "5px" }}>{warning.message}</div>
            <div
              style={{
                fontFamily: "monospace",
                fontWeight: "bold",
                color: "white",
              }}
            >
              propName:{" "}
              <span style={{ fontFamily: "monospace", fontWeight: "normal" }}>
                {warning.propName}
              </span>
            </div>

            <div
              style={{
                fontFamily: "monospace",
                fontWeight: "bold",
                color: "white",
              }}
            >
              value:{" "}
              <span style={{ fontFamily: "monospace", fontWeight: "normal" }}>
                {formatValue(warning.value)}
              </span>
            </div>
          </li>
        ))}
      </ul>
    );
  };

  const renderUnitCorrectionsList = () => {
    // Kontroll om unitCorrections existerar och har element
    if (!unitCorrections || unitCorrections.length === 0) {
      return (
        <>
          <p style={{ fontWeight: "bold" }}>
            Följande måttenheter har ändrats:
          </p>
          <p style={{ fontStyle: "italic", color: "var(--kurr-white55)" }}>
            Inga måttenheter har ändrats.
          </p>
        </>
      );
    }

    // Returnera listan om det finns korrigeringar
    return (
      <>
        <p style={{ fontWeight: "bold" }}>Följande måttenheter har ändrats:</p>
        <ul style={{ paddingLeft: "0px" }}>
          {unitCorrections.map((correction, index) => (
            <li
              key={index}
              style={{ listStylePosition: "inside", marginBottom: "4px" }}
            >
              <span style={{ fontStyle: "italic" }}>
                {correction.invalidIngredient.name}{" "}
                {correction.invalidIngredient.quantity
                  ? `${correction.invalidIngredient.quantity} `
                  : ""}
                {correction.invalidIngredient.unit}
              </span>
              <span style={{ fontWeight: "bold" }}> → </span>
              <span style={{ fontStyle: "italic" }}>
                {correction.validIngredient.name}{" "}
                {correction.validIngredient.quantity
                  ? `${correction.validIngredient.quantity} `
                  : ""}
                {correction.validIngredient.unit}
              </span>
              {isAdmin && (
                <div
                  style={{
                    display: "block",
                    marginTop: "2px",
                    marginBottom: "4px",
                    paddingLeft: "13px",
                    color: "var(--ios-yellow-system-dark)",
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>Motivering:</span>
                  <span> {correction.reason}</span>
                </div>
              )}
            </li>
          ))}
        </ul>
      </>
    );
  };

  const renderSourceLink = () => (
    <p style={{ fontWeight: "bold" }}>
      Källa:{" "}
      <span style={{ fontWeight: "normal" }}>
        {sourceUrl ? (
          <a
            style={{
              color: "var(--kurr-white55)",
              textDecoration: "underline",
            }}
            href={sourceUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {sourceUrl}
          </a>
        ) : (
          <span
            style={{
              textDecoration: "none",
              fontStyle: "italic",
              color: "var(--kurr-white55)",
            }}
          >
            Saknas
          </span>
        )}
      </span>
    </p>
  );

  const renderAutoUploadInfoIntroText = () => {
    return (
      <div>
        <p style={{ fontWeight: "bold" }}>Kontrollera noggrannt:</p>
        <ul style={{ paddingLeft: "0px" }}>
          <li
            style={{
              listStylePosition: "inside",
              marginBottom: "4px",
              color: "var(--kurr-white55)",
              fontStyle: "italic",
            }}
          >
            Att inga ingredienser saknas.
          </li>
          <li
            style={{
              listStylePosition: "inside",
              marginBottom: "4px",
              color: "var(--kurr-white55)",
              fontStyle: "italic",
            }}
          >
            Att alla ingredienser är korrekt placerade.
          </li>
          <li
            style={{
              listStylePosition: "inside",
              marginBottom: "4px",
              color: "var(--kurr-white55)",
              fontStyle: "italic",
            }}
          >
            Ändringar av måttenheter (se lista nedan).
          </li>
        </ul>
      </div>
    );
  };

  const renderAutoUploadInfo = () => {
    return (
      <>
        {renderAutoUploadInfoIntroText()}
        {renderUnitCorrectionsList()}
        {renderSourceLink()}
      </>
    );
  };

  const publishRecipeHandler = async () => {
    try {
      if (isLoading) {
        console.log("Loading...");
        return;
      }
      if (isPublic) {
        const confirmed = window.confirm(
          "Är du säker på att du vill avpublicera receptet?"
        );
        if (!confirmed) return;
      }

      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL +
          `/api/recipes/${props.id}/toggle-public`,
        "PATCH",
        JSON.stringify({}),
        { "Content-Type": "application/json" }
      );
      setIsPublic(responseData.newStatus);
      setJustPublished(responseData.newStatus);
    } catch (err) {
      alert(
        `Något gick fel! Det gick inte att ${
          isPublic ? "publicera" : "avpublicera"
        } receptet. Försök igen senare.`
      );
    }
  };

  // Funktion för att kopiera URLen till urklippet
  const copyRecipeUrlToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(props.deepLinkUrl);
      setShareLinkText("Kopierad!");

      setTimeout(() => {
        setShareLinkText("Delningslänk");
      }, 2000);
    } catch (err) {
      Alert(
        "Det gick inte att kopiera till urklipp, testa att kopiera denna manuellt: ",
        props.deepLinkUrl
      );
      console.error(
        "Det gick inte att kopiera till urklipp, testa att kopiera denna manuellt: ",
        props.deepLinkUrl,
        " Felmeddelande: ",
        err
      );
    }
  };

  const ShareLinkButton = () => {
    const handleClick = (event) => {
      if (!props.complete) {
        event.stopPropagation();
        return;
      }
      copyRecipeUrlToClipboard();
    };

    return (
      <div className="btn-share-link" onClick={handleClick}>
        <ContentCopy
          style={{
            marginRight: "2px", // Lägg till lite marginal mellan ikonen och texten
            color:
              !props.complete || !isPublic
                ? "var(--kurr-black25)"
                : "var(--kurr-black85)",
            fontSize: 14,
            cursor: props.complete && isPublic ? "pointer" : "default",
          }}
        />
        <span // Använd en span istället för en knapp för att visa texten
          className="btn-publish"
          style={{
            color:
              !props.complete || !isPublic
                ? "var(--kurr-black25)"
                : "var(--kurr-black85)",
            cursor: props.complete && isPublic ? "pointer" : "default",
          }}
        >
          {shareLinkText}
        </span>
      </div>
    );
  };

  const PublishButton = () => {
    return (
      <div className="btn-publish-container">
        <span
          className="published-feedback"
          style={{ paddingRight: justPublished && isPublic ? "4px" : 0 }}
        >
          {isLoading ? "" : justPublished && isPublic ? "Publicerat! 🥳" : ""}
        </span>
        <button
          className="btn-publish"
          disabled={props.complete ? false : true}
          style={{
            color: !props.complete
              ? "var(--kurr-black25)"
              : isPublic
              ? justPublished
                ? "var(--kurr-black85)"
                : "var(--piglet-peach)"
              : "var(--kurr-black85)",
          }}
          onClick={publishRecipeHandler}
        >
          {isLoading
            ? isPublic
              ? "Avpublicerar..."
              : "Publicerar..."
            : isPublic
            ? justPublished
              ? "Ångra"
              : "Avpublicera"
            : "Publicera"}
        </button>
      </div>
    );
  };

  const Actions = () => {
    return (
      <div className="actions">
        {!props.complete && (
          <div className="recipe-summary-item__recipe--incomplete">
            {complete}
          </div>
        )}
        {/* Villkorligt rendera divider endast om `complete` är true */}
        {complete && <div className="actions-divider">|</div>}
        <PublishButton />
        {/* TODO: WEB-40: Add ShareLinkButton back when its time to prop for reciepe sharing*/}

        <div className="actions-divider">|</div>
        <ShareLinkButton />
      </div>
    );
  };

  return (
    <li className="recipe-summary-item">
      <hr className="recipe-summary-item__line" />
      <div className="recipe-summary-item__label">{props.label}</div>
      <div className="recipe-summary-item__value">
        <div>
          {props.title}{" "}
          {/* {missingIngredientDbId && !isAdmin && (
            <span
              style={{
                verticalAlign: "middle",
                paddingLeft: "8px",
              }}
            >
              <Warning
                style={{
                  color: `${"var(--ios-red)"}`,
                  fontSize: "20px",
                }}
              />
            </span>
          )} */}
          {isAutoUploaded && (
            <Tooltip
              title={renderAutoUploadInfo()}
              placement="right"
              open={autoUploadTooltipOpen}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              interactive
            >
              <span
                onClick={handleautoUploadTooltipToggle}
                style={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  alignItems: "center",
                  //paddingLeft: "8px",
                  paddingBottom: "4px",
                  cursor: "pointer",
                }}
              >
                <AutoAwesome
                  style={{
                    color: `${"var(--ios-yellow)"}`,
                    fontSize: "20px",
                    marginRight: "0px",
                  }}
                />
              </span>
            </Tooltip>
          )}
          {(isAdmin ? warnings.length > 0 : creatorWarnings.length > 0) && (
            <Tooltip
              title={
                isAdmin ? renderWarningsList() : renderCreatorWarningsList()
              }
              placement="right"
              open={warningsTooltipOpen}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              interactive
            >
              <span
                onClick={handleWarningsTooltipToggle}
                style={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  alignItems: "center",
                  paddingLeft: "8px",
                  paddingBottom: "4px",
                  cursor: "pointer",
                }}
              >
                <Warning
                  style={{
                    color: getWarningIconColor(),
                    fontSize: "20px",
                  }}
                />
                <span
                  style={{
                    marginLeft: "4px",
                    fontFamily: "monospace",
                    fontSize: "12px",
                    fontWeight: "bold",
                    color: `${"var(--kurr-black45)"}`,
                    marginTop: "4px",
                  }}
                >
                  {isAdmin ? warnings.length : null}
                </span>
              </span>
            </Tooltip>
          )}
          {isAdmin && isNew && (
            <span className="new-recipe">NY! {formattedDate}</span>
          )}
          {isAdmin && <div className="username">{"@" + creator}</div>}
          <Actions />
        </div>
        <Link to={`/recipe/${props.id}/step-4`}>
          <MoreHoriz className="recipe-more-icon" />
        </Link>
      </div>
    </li>
  );
};

export default RecipeItem;
