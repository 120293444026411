/**
 * PRIVATE
 * Converts instruction to correct formate for the client app
 */

const dbToUploadFormConverter = (dbInstructions) => {
  let clientInstructions = [];

  dbInstructions.map((dbInstruction) => {
    // Sections
    if (dbInstruction.section) {
      clientInstructions.push({
        id: dbInstruction.id,
        section: {
          value: dbInstruction.section,
          isValid: true,
        },
      });

      // Instructions
    } else if (dbInstruction.text) {
      let clientIngretientsArr = [];

      // Ingredients
      if (dbInstruction.ingredients && dbInstruction.ingredients.length > 0) {
        dbInstruction.ingredients.map((dbIngredient) => {
          clientIngretientsArr.push({
            id: dbIngredient.id,
            ingredientDbId: {
              value: dbIngredient.ingredientDbId,
            },
            name: {
              value: dbIngredient.name,
              isValid: true,
            },
            quantity: {
              value: dbIngredient.quantity,
              isValid: true,
            },
            unit: {
              value: dbIngredient.unit,
              isValid: true,
            },
          });
        });
      }

      // Add instruction and belonging ingredients
      clientInstructions.push({
        id: dbInstruction.id,
        text: {
          value: dbInstruction.text,
          isValid: true,
        },
        ingredients: clientIngretientsArr,
      });
    }
  });
  return clientInstructions;
};

export default dbToUploadFormConverter;