import { React, useEffect, useState, useContext } from "react";
import { AuthContext } from "../../shared/context/auth-context";

import { v4 as uuidv4 } from "uuid";

import Input from "../../shared/components/FormElements/Input";
import {
  VALIDATOR_MAXLENGTH,
  VALIDATOR_REQUIRE,
} from "../../shared/util/validators";

import Ingredient from "./Ingredient";
import "./RecipeInstruction.css";
import { MoreHoriz } from "@mui/icons-material";

const isValidObjectId = (str) => {
  if (!str) {
    return false;
  }
  const objectIdRegex = /^[0-9a-fA-F]{24}$/;
  return objectIdRegex.test(str);
};

const RecipeInstruction = (props) => {
  const {
    setFormData,
    instructionId,
    formState,
    activeInstructionId,
    setActiveInstructionId,
  } = props;

  const { user } = useContext(AuthContext);

  const [isActive, setIsActive] = useState(false);
  const [brokenIngredients, setBrokenIngredients] = useState([]);

  useEffect(() => {
    let tempBrokenIngredients = [];

    props.ingredientsList.forEach((ingredient) => {
      if (!isValidObjectId(ingredient.ingredientDbId.value)) {
        tempBrokenIngredients.push(ingredient.id);
      }
    });

    if (tempBrokenIngredients.length > 0) {
      setBrokenIngredients(() => [...tempBrokenIngredients]);
    }
  }, []);

  /*Toggle active state */
  useEffect(() => {
    if (activeInstructionId === instructionId) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [activeInstructionId]);

  const createNewIngredientHandler = () => {
    // Generate a new uuid
    let newId = uuidv4();

    setFormData(
      {
        id: newId,

        ingredientDbId: {
          value: "",
        },

        name: {
          value: "",
          isValid: false,
        },
        quantity: {
          value: "",
          isValid: false,
        },
        unit: {
          value: "",
          isValid: false,
        },
      },
      false,
      "create_ingredient",
      instructionId
    );
  };

  const deleteIngredientHandler = (ingredientId) => {
    // Remove ingredient from state
    setFormData({}, true, "delete_ingredient", instructionId, ingredientId);

    // Upload state to database
    props.uploadInstructionsHandler();
  };

  return (
    /* Om aktiv så renderas active-CSS:en, annars inactive-CSS:en */
    <div
      className={
        isActive
          ? `step-by-step-instruction-active`
          : `step-by-step-instruction-inactive`
      }
    >
      {/*<Close fontSize="medium" className="delete-instruction" />*/}
      <p
        onClick={() => {
          if (window.confirm("Är du säker på att du vill radera steget?")) {
            props.deleteInstructionHandler(instructionId);
          }
        }}
        className="delete-instruction"
      >
        Radera steg
      </p>

      <div className="input-text-and-icon-container">
        <Input
          style={`${
            isActive ? "description-input-active" : "description-input-inactive"
          }`}
          formState={props.formState}
          setFormData={props.setFormData}
          element="input"
          id="instructionText"
          type="text"
          placeholder="Kort beskrivning av steget"
          label={user.isAdmin ? `Index [${props.index}]` : "Steg"}
          validators={[VALIDATOR_REQUIRE(), VALIDATOR_MAXLENGTH(280)]}
          maxInput="280"
          errorText=""
          charCounter={true}
          onInput={props.inputHandler}
          instructionId={props.instructionId}
          initialValue={props.value}
          warningIcon={user.isAdmin && brokenIngredients.length > 0}
        />
        {!isActive && (
          <div className="inactive-text-and-icon-container">
            <div style={{ flexDirection: "column" }}>
              <div className="step-by-step-inactive_text">{props.value}</div>
              {props.ingredientsList.length > 0 && (
                <div className="ingredients-string">
                  {props.ingredientsList
                    .map((ingredient) => {
                      let ingredientString = "";

                      if (ingredient.name) {
                        ingredientString += `${ingredient.name.value} `;
                        if (!ingredient.name.value) {
                          ingredientString += "INGREDIENS SAKNAS ";
                        }
                      }
                      if (
                        ingredient.quantity &&
                        ingredient?.unit.value !== "efter smak"
                      ) {
                        ingredientString += `${ingredient.quantity.value} `;
                        if (!ingredient.quantity.value) {
                          ingredientString += "MÄNGD SAKNAS ";
                        }
                      }
                      if (ingredient.unit) {
                        ingredientString += `${ingredient.unit.value}`;
                        if (!ingredient.unit.value) {
                          ingredientString += "MÅTTENHET SAKNAS ";
                        }
                      }

                      return ingredientString;
                    })
                    .join(", ")}
                </div>
              )}
            </div>

            <div className="step-by-step_inactive_more-icon">
              <MoreHoriz
                className={`recipe-more-icon ${
                  !formState.isValid && activeInstructionId !== null
                    ? `recipe-more-icon-hide`
                    : null
                }`}
                onClick={() => {
                  setActiveInstructionId(instructionId);
                }}
              />
            </div>
          </div>
        )}
      </div>

      {isActive && (
        <div>
          <ul>
            {props.ingredientsList.map((ingredient, index) => (
              <li key={index}>
                <Ingredient
                  deleteIngredientHandler={deleteIngredientHandler}
                  setIngredientsList={props.setIngredientsList}
                  inputHandler={props.inputHandler}
                  instructionId={props.instructionId}
                  initialNameValue={ingredient.name.value}
                  initialQuantityValue={ingredient.quantity.value}
                  initialUnitValue={ingredient.unit.value}
                  ingredientId={ingredient.id}
                  ingredientDbId={ingredient.ingredientDbId.value}
                  fullIngredientList={props.fullIngredientList}
                  setFormData={props.setFormData}
                  userId={props.userId}
                  formState={props.formState}
                  brokenIngredients={brokenIngredients}
                  setBrokenIngredients={setBrokenIngredients}
                  isAdmin={user.isAdmin}
                />
              </li>
            ))}
          </ul>
          <div className="ingredient-buttons">
            <button
              type="submit"
              className="button-add-ingredient"
              onClick={createNewIngredientHandler}
              disabled={!formState.isValid}
            >
              Lägg till ingrediens
            </button>
            <button
              onClick={() => {
                // Upload state to database
                props.uploadInstructionsHandler();
                setActiveInstructionId(null);
              }}
              type="submit"
              className="button-ingredient-done"
              disabled={!formState.isValid}
            >
              Klar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default RecipeInstruction;
