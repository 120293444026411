import React, { Fragment, useState, useEffect } from "react";
import { HighlightOff, Warning } from "@mui/icons-material";

import Input from "../../shared/components/FormElements/Input";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "../../shared/hooks/http-hook";
import {
  VALIDATOR_MINLENGTH,
  VALIDATOR_REQUIRE,
} from "../../shared/util/validators";
import "./Ingredient.css";

const isValidObjectId = (str) => {
  if (!str) {
    return false;
  }
  const objectIdRegex = /^[0-9a-fA-F]{24}$/;
  return objectIdRegex.test(str);
};

const Ingredient = (props) => {
  const {
    fullIngredientList,
    setFormData,
    instructionId,
    ingredientId,
    userId,
    setIngredientsList,
    formState,
    brokenIngredients,
    setBrokenIngredients,
  } = props;
  let { ingredientDbId } = props;

  const [ingredientSuggestions, setIngredientSuggestions] = useState([]);
  const [input, setInput] = useState(props.initialNameValue || "");
  const [ingredientAdded, setIngredientAdded] = useState(false);
  const [quantityDisabled, setQuantityDisabled] = useState(false);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  // Function used in parent to decide if Warning should be shown or not
  useEffect(() => {
    if (
      !isValidObjectId(ingredientDbId) &&
      !brokenIngredients.includes(ingredientId)
    ) {
      setBrokenIngredients((prev) => [...prev, ingredientId]);
    } else if (
      isValidObjectId(ingredientDbId) &&
      brokenIngredients.includes(ingredientId)
    ) {
      setBrokenIngredients((prev) => prev.filter((id) => id !== ingredientId));
    }
  }, [brokenIngredients, ingredientDbId]);

  // Really ugly function to disable quantity if unit is "efter smak"
  useEffect(() => {
    const instructions = formState.inputs.instructions;
    const instruction = instructions.find(
      (instruction) => instruction.id === instructionId
    );
    const ingredients = instruction.ingredients;
    const ingredient = ingredients.find(
      (ingredient) => ingredient.id === ingredientId
    );

    if (ingredient.unit.value === "efter smak") {
      setQuantityDisabled(true);
    } else {
      setQuantityDisabled(false);
    }
  }, [formState]);

  // Prevents "add ingredient" to pop up on every re-render
  useEffect(() => {
    setIngredientAdded(true);
  }, []);

  // Adapt the ingredients in the list based on what the user types in the search input field
  const onSearchHandler = (event) => {
    event.preventDefault();

    // If user writes in a input field where a ingredient has previously been added to formState, remove that ingredient from formState
    setIngredientAdded(false);
    setFormData(
      {
        ingredientDbId: {
          value: "",
        },

        name: {
          value: "",
          isValid: false,
        },
      },
      false,
      "set_ingredient",
      instructionId,
      ingredientId
    );

    const inputWithFirstLetterCapitalized =
      event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);

    setInput(inputWithFirstLetterCapitalized);

    let matches = [];
    matches = fullIngredientList.filter((igr) => {
      const regex = new RegExp(`${inputWithFirstLetterCapitalized}`, "gi");
      return igr?.name?.match(regex);
    });

    // Sort matches based on how the first letters match the input
    matches.sort((a, b) => {
      const regex = new RegExp(`^${inputWithFirstLetterCapitalized}`, "i");
      const aMatch = a?.name?.match(regex);
      const bMatch = b?.name?.match(regex);
      if (aMatch && bMatch) {
        return a.name.length - b.name.length;
      } else if (aMatch) {
        return -1;
      } else if (bMatch) {
        return 1;
      } else {
        return 0;
      }
    });

    setIngredientSuggestions(matches);
  };

  const addIngredientHandler = (ingredient) => {
    setInput(ingredient.name);
    // variabel som håller reda på om man klickat på något i listan
    setIngredientAdded(true);
    setFormData(
      {
        ingredientDbId: {
          value: ingredient._id,
        },

        name: {
          value: ingredient.name,
          isValid: true,
        },
      },
      true,
      "set_ingredient",
      instructionId,
      ingredientId
    );
    // Clear list with ingredientsuggestions
    setIngredientSuggestions([]);
  };

  const manuallyAddIngredient = async (event) => {
    if (window.confirm("Vill du lägga till ingrediensen manuellt?")) {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/api/ingredients/",
          "POST",
          JSON.stringify({
            name: input,
            addedByUserId: userId,
          }),
          { "Content-Type": "application/json" }
        );
        addIngredientHandler(responseData.ingredient);
      } catch (err) {}
    }

    event.preventDefault();
  };

  return (
    <Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && <LoadingSpinner asOverLay />}
      {!isLoading && (
        <Fragment>
          <div className="ingredient-container">
            {!isValidObjectId(ingredientDbId) && props.isAdmin && (
              <Warning className="warning-icon" style={{ fontSize: "20px" }} />
            )}

            <div className="form-control ingredient ingredient__name">
              <input
                id="search"
                type="text"
                placeholder="Ingrediens"
                onChange={onSearchHandler}
                onInput={onSearchHandler}
                onPaste={onSearchHandler}
                contentEditable="true"
                autoComplete="off"
                onBlur={() => {
                  if (ingredientSuggestions.length > 0 && !ingredientAdded) {
                    addIngredientHandler(ingredientSuggestions[0]);
                  }
                  setIngredientSuggestions([]);
                }}
                value={input}
              />

              <ul className="search-suggestions">
                {ingredientSuggestions &&
                  ingredientSuggestions.map((suggestion) => (
                    <li
                      key={suggestion.id}
                      onMouseDown={() => {
                        addIngredientHandler(suggestion);
                      }}
                    >
                      {suggestion.name}
                    </li>
                  ))}

                {!ingredientAdded &&
                  input &&
                  !ingredientSuggestions.some((igr) => igr.name === input) && (
                    <li>
                      <div
                        className="add-ingredient-button"
                        onClick={manuallyAddIngredient}
                      >
                        + Lägg till <span>{input}</span>
                      </div>
                    </li>
                  )}
              </ul>
            </div>

            <div className="ingredient__quantity">
              <Input
                element="input"
                className="ingredient__quantity"
                id="quantity"
                type="number"
                placeholder="Antal"
                validators={[VALIDATOR_MINLENGTH(1)]}
                errorText="Vänliggen ange mängd"
                onInput={props.inputHandler}
                instructionId={instructionId}
                ingredientId={ingredientId}
                initialValue={props.initialQuantityValue}
                style="ingredient ingredient__quantity"
                disabled={quantityDisabled}
                formState={formState}
              />
            </div>

            <div className="ingredient__unit">
              <Input
                element="select"
                id="unit"
                type="text"
                placeholder="Enhet"
                validators={[VALIDATOR_REQUIRE]}
                errorText="Vänligen ange måttenhet"
                onInput={props.inputHandler}
                select={[
                  "krm",
                  "tsk",
                  "msk",
                  "ml",
                  "cl",
                  "dl",
                  "liter",
                  "g",
                  "hg",
                  "kg",
                  "st",
                  "förp",
                  "efter smak",
                ]}
                instructionId={instructionId}
                ingredientId={ingredientId}
                initialValue={props.initialUnitValue}
                style="ingredient ingredient__unit"
              />
            </div>

            <div className="ingredient-delete-container">
              <HighlightOff
                onClick={() => {
                  props.deleteIngredientHandler(ingredientId);
                }}
                fontSize="small"
                className="delete-ingredient-button"
              />
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default Ingredient;
