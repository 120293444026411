import React, { Fragment, useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import RecipeUploadHeader from "../../recipes/components/progressBar/RecipeUploadHeader";
import Input from "../../shared/components/FormElements/Input";
import Card from "../../shared/components/UIElements/Card";
import {
  VALIDATOR_EMAIL,
  VALIDATOR_MINLENGTH,
  VALIDATOR_REQUIRE,
} from "../../shared/util/validators";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import { useForm } from "../../shared/hooks/form-hook";
import { useHttpClient } from "../../shared/hooks/http-hook";
import Button from "../../shared/components/FormElements/Button";
import { AuthContext } from "../../shared/context/auth-context";
// import FileUpload from "../../shared/components/FormElements/FileUpload";
import "./Auth.css";
import { UserType } from "../../shared/enums/user-type.enum";

const Auth = () => {
  const auth = useContext(AuthContext);
  const [isLoginMode, setIsLoginMode] = useState(true);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const history = useHistory();

  const [formState, inputHandler, setFormData] = useForm(
    {
      email: {
        value: "",
        isValid: false,
      },
      password: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  const switchModeHandler = () => {
    if (!isLoginMode) {
      setFormData(
        {
          ...formState.inputs,
          name: undefined,
          //image: undefined,
        },
        formState.inputs.email.isValid && formState.inputs.password.isValid
      );
    } else {
      setFormData(
        {
          ...formState.inputs,
          name: {
            value: "",
            isValid: false,
          },
          /*
          image: {
            value: null,
            isValid: false,
          },
          */
        },
        false
      );
    }
    setIsLoginMode((prevMode) => !prevMode);
  };

  const authSubmitHandler = async (event) => {
    event.preventDefault();

    if (isLoginMode) {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/api/users/login",
          "POST",
          JSON.stringify({
            userData: formState.inputs.email.value,
            password: formState.inputs.password.value,
            routedFromUploadForm: true,
          }),
          {
            "Content-Type": "application/json",
          }
        );

        auth.login(responseData.token);
        // history.push(`/${responseData.user.id}/recipes`);
      } catch (err) {}
    } else {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/api/users/signup",
          "POST",
          JSON.stringify({
            name: formState.inputs.name.value,
            username: formState.inputs.username.value,
            phone: formState.inputs.phonenumber.value,
            email: formState.inputs.email.value,
            password: formState.inputs.password.value,
            userType: UserType.CREATOR,
            routedFromUploadForm: true,
          }),
          {
            "Content-Type": "application/json",
          }
        );
        //routa till ny page, skicka user id som prop

        // auth.login(responseData.user.id);
        history.push(`/${responseData.userId}/verify`);
      } catch (err) {}
    }
  };

  const onBackLogin = () => {
    history.push("/");
  };

  const onBackSignup = () => {
    setIsLoginMode(true);
  };

  return (
    <Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <Card className="authentication">
        {isLoading && <LoadingSpinner asOverlay />}
        {isLoginMode && (
          <RecipeUploadHeader
            onBack={onBackLogin}
            currentStep={null}
            title="Logga in"
          />
        )}
        {!isLoginMode && (
          <RecipeUploadHeader
            onBack={onBackSignup}
            currentStep={null}
            title="Skapa konto"
          />
        )}

        <form onSubmit={authSubmitHandler}>
          {!isLoginMode && (
            <Fragment>
              <Input
                element="input"
                id="name"
                type="text"
                placeholder=""
                label="För- och efternamn"
                validators={[VALIDATOR_REQUIRE()]}
                errorText="Var vänlig ange ditt namn."
                onInput={inputHandler}
              />
              <Input
                element="input"
                id="username"
                type="text"
                placeholder=""
                label="Användarnamn"
                validators={[VALIDATOR_REQUIRE()]}
                errorText="Var vänlig ange ditt användarnamn."
                onInput={inputHandler}
              />
              <Input
                element="input"
                id="phonenumber"
                type="tel"
                placeholder=""
                validators={[]}
                label="Telefonnummer"
                errorText="Ange ett giltigt telefonnummer."
                onInput={inputHandler}
              />
            </Fragment>
          )}
          <Input
            element="input"
            id="email"
            type="email"
            placeholder=""
            label="E-post"
            validators={[VALIDATOR_EMAIL()]}
            errorText="Ange en giltig E-mail adress"
            onInput={inputHandler}
          />
          <Input
            element="input"
            id="password"
            type="password"
            placeholder=""
            label="Lösenord"
            validators={[VALIDATOR_MINLENGTH(6)]}
            onInput={inputHandler}
          />
          <Button type="submit" disabled={!formState.isValid}>
            {isLoginMode ? "Logga in" : "Registrera"}
            <Link to="/" />
          </Button>
        </form>
        <Button onClick={switchModeHandler} center inverse>
          {/*isLoginMode ? "Inte medlem? Registrera" : "Redan medlem? Logga in"*/}
        </Button>
      </Card>
    </Fragment>
  );
};

export default Auth;

/*
        {!isLoginMode && (
          <FileUpload id="image" center onInput={inputHandler} />
        )}
*/
