import { createContext, useState } from "react";
import jwt_decode from "jwt-decode";

export const AuthContext = createContext({
  login: (token) => {},
  logout: () => {},
  token: null,
  isLoggedIn: false,
  user: null,
});

const AuthContextProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState();
  const [user, setUser] = useState({});

  const login = (token) => {
    const decoded = jwt_decode(token);
    setUser({
      name: decoded.name,
      id: decoded.userId,
      email: decoded.email,
      isAdmin: decoded.isAdmin,
    });
    setAuthToken(token);
    // save token to localStorage
    localStorage.setItem("token", token);
  };

  const logout = () => {
    localStorage.removeItem("token");
    setAuthToken(null);
  };

  const value = {
    login: login,
    logout: logout,
    token: authToken,
    isLoggedIn: !!authToken,
    user: user,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;
