import "./Recipe.css";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import kurrLogo from "../../shared/assets/kurr-logo_v2_white.svg";
import appStoreBadge from "../../shared/assets/app-store-badge.svg";
import googlePlayBadge from "../../shared/assets/google-play-badge.svg";
import videoBgDesktop from "../../shared/assets/macaronies-desktop.mp4";
import posterBgDesktop from "../../shared/assets/desktop-poster.png";

const RedirectToStore = () => {
  const videoRef = useRef();
  const [videoUrl, setVideoUrl] = useState(videoBgDesktop);
  const [bgPoster, setBgPoster] = useState(posterBgDesktop);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    let attemptCount = 0;
    const maxAttempts = 7;
    const retryDelay = 2 * 1000;

    const openAppStoreWithRetry = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      let storeUrl = "";

      const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
      const isMac = /Macintosh/.test(userAgent);
      const isAndroid = /android/i.test(userAgent);

      if (isIOS || isMac) {
        storeUrl =
          "https://apps.apple.com/se/app/kurr/id6449964264?platform=iphone";
      } else if (isAndroid) {
        storeUrl =
          "https://play.google.com/store/apps/details?id=app.kurr.play";
      }

      if (storeUrl) {
        window.location.href = storeUrl;

        attemptCount++;
        if (attemptCount < maxAttempts) {
          setTimeout(() => {
            if (document.visibilityState === "visible") {
              openAppStoreWithRetry();
            }
          }, retryDelay);
        }
      }
    };

    openAppStoreWithRetry();
  }, []);

  return (
    <div className="hero">
      <Helmet>
        <meta
          property="og:image"
          content="https://kurr-images-bucket.s3.eu-north-1.amazonaws.com/kurr_link_logo.png"
        />
        <meta property="og:title" content="Kurr - Svajpa · Matcha · Käka!" />
      </Helmet>
      <div className="hero__overlay">
        <div className="hero__overlay--full" />
        <div className="hero__overlay--top-gradient" />
        <div className="hero__overlay--bottom-gradient" />
      </div>
      <video
        className="hero__video"
        ref={videoRef}
        src={videoUrl}
        poster={bgPoster}
        autoPlay
        loop
        muted
        playsInline
      />

      <div className="hero__content">
        <img className="hero__content-logo" src={kurrLogo} alt="Kurr logo" />
      </div>
      <div className="hero__app-store">
        <div className="hero_app-store--heading">SKAFFA APPEN</div>
        <div className="hero__app-store__icons">
          <img
            className="hero_app-store__icons--size"
            src={appStoreBadge}
            alt="App Store"
            onClick={() => {
              window.open(
                "https://apps.apple.com/se/app/kurr/id6449964264?platform=iphone",
                "_blank",
                "noreferrer"
              );
            }}
          />
          <img
            className="hero_app-store__icons--size"
            src={googlePlayBadge}
            alt="Google Play"
            onClick={() => {
              window.open(
                "https://play.google.com/store/apps/details?id=app.kurr.play",
                "_blank",
                "noreferrer"
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default RedirectToStore;
