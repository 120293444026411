import { createContext, useContext, useEffect } from "react";
import Tracker from "./tracker";

const TrackerContext = createContext(null);

export const useTracker = () => {
  return useContext(TrackerContext);
};

export const TrackerProvider = ({ children }) => {
  useEffect(() => {
    Tracker.initializeTracker();
  }, []);

  return (
    <TrackerContext.Provider value={Tracker}>
      {children}
    </TrackerContext.Provider>
  );
};
