import React from "react";
import { useLocation } from "react-router-dom";

import pasta from "../../../shared/assets/pasta.svg";
import "./Footer.css";

const Footer = () => {
  const location = useLocation();

  return (
    <div
      className={` footer-container ${
        location.pathname === "/" || location.pathname === "/menu"
          ? "footer-container--hidden"
          : null
      }`}
    >
      <div className="dividing-line" />
      <div className="footer-symbols">
        <h3>KURR AB ®</h3>
        <img src={pasta} alt="pasta" />
      </div>
    </div>
  );
};

export default Footer;

{
  /*<div
      className={` footer-container ${
        window.location.pathname === "/" || "/menu"
          ? "footer-container--hidden"
          : null
      }`}
    >*/
}
