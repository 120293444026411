import { ArrowBackIos } from "@mui/icons-material";
import React from "react";

import RecipeUploadProgressBar from "./RecipeUploadProgressBar";
import "./RecipeUploadHeader.css";

const RecipeUploadHeader = (props) => {
  return (
    <div className="recipe-upload-header" onClick={props.onClick}>
      <RecipeUploadProgressBar currentStep={props.currentStep} />
      <div className="recipe-upload-header__navigation">
        <div className="recipe-upload-header__navigation--spacing-container-left-and-right">
          {props.hideBackButton ? null : (
            <div className="recipe-upload-header__navigation--back-button">
              <ArrowBackIos
                onClick={props.onBack}
                className="recipe-upload-header__navigation--back-button__icon"
              />
            </div>
          )}
          {/* <div className="recipe-upload-header__navigation--back-arrow">
            <ArrowBackIos />
          </div> */}
        </div>
        <div className="recipe-upload-header__navigation--spacing-container-center">
          <h2 className="recipe-upload-header__navigation--title">
            {props.title}
          </h2>
        </div>
        <div className="recipe-upload-header__navigation--spacing-container-left-and-right"></div>
      </div>
    </div>
  );
};

export default RecipeUploadHeader;
