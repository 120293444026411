import React, { useEffect, useRef, useState } from "react";
import { Add } from "@mui/icons-material";

import Button from "./Button";
import "./FileUpload.css";

const FileUpload = (props) => {
  const [file, setFile] = useState(); // hold state of individual file
  const [isValid, setIsValid] = useState(false);

  const filePickerRef = useRef();

  useEffect(() => {
    if (!file) {
      return;
    }
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
  }, [file]);

  const pickedHandler = (event) => {
    let pickedFile;
    let fileIsValid = isValid;
    if (event.target.files && event.target.files.length === 1) {
      // Get the size of the file (bytes)
      const fileSize = event.target.files[0].size;
      // Convert the size into megabytes
      const fileSizeMB = (fileSize / 1024 / 1024).toFixed(2);
      // Check that the size of the file is within limits
      if (fileSizeMB > 650) {
        alert(
          "Filen som valts är för stor, var vänlig välj en fil som är mindre än 650 MB."
        );
      } else {
        pickedFile = event.target.files[0];
        setFile(pickedFile);
        setIsValid(true);
        fileIsValid = true;
      }
    } else {
      setIsValid(false);
      fileIsValid = false;
    }

    // Call on funciton to upload image or video
    if (props.id === "image") {
      props.uploadImage(pickedFile, fileIsValid);
    } else {
      props.uploadVideo(pickedFile, fileIsValid);
    }
  };

  const pickFileHandler = () => {
    filePickerRef.current.click();
  };

  return (
    <div className="form-control">
      <input
        name={props.name}
        id={props.id}
        ref={filePickerRef}
        style={{ display: "none" }}
        type="file"
        accept={props.accept}
        onChange={pickedHandler}
      />
      <div className="file-upload">
        <div className="file-upload__preview" />
        <Button type="button" onClick={pickFileHandler} fileUpload>
          <Add fontSize="small" />
        </Button>
      </div>
    </div>
  );
};

export default FileUpload;
