import { useCallback, useReducer } from "react";

const formReducer = (state, action) => {
  let newState;
  let instructionIndex;
  let ingredientIndex;
  let formIsValid;
  let formValidity;

  switch (action.type) {
    case "INSTRUCTION_CHANGE_TEXT":
      newState = { ...state };
      // Set the validity of formstate to true
      formValidity = true;
      // Make the formstate invalid if the incoming action is not valid
      if (!action.isValid) {
        formValidity = false;
      }

      newState.inputs.instructions.forEach((instruction, index) => {
        // Check the validity of all the previous object and input fields in the state
        if (
          JSON.stringify(instruction.id) ===
          JSON.stringify(action.instructionId)
        ) {
          //Handle if the input field is an instruction or section
          if (instruction.text) {
            newState.inputs.instructions[index].text.value = action.value;
            newState.inputs.instructions[index].text.isValid = action.isValid;
          } else if (instruction.section) {
            newState.inputs.instructions[index].section.value = action.value;
            newState.inputs.instructions[index].section.isValid =
              action.isValid;
          }
        } else {
          if (instruction.text && !instruction.text.isValid) {
            formValidity = false;
          } else if (instruction.section && !instruction.section.isValid) {
            formValidity = false;
          }
        }
      });

      newState.isValid = formValidity;
      return newState;

    case "TITLE_CHANGE":
      newState = { ...state };
      formValidity = true;

      // Set formvalidity to false if the length of the image-array is 0, if the validity of the description is not valid or if incoming validity is false.
      if (
        state.inputs.images.length === 0 ||
        !state.inputs.description.isValid ||
        !action.isValid
      ) {
        formValidity = false;
      }

      // Set the incoming value in state
      newState.inputs.title.value = action.value;
      newState.inputs.title.isValid = action.isValid;

      newState.isValid = formValidity;
      return newState;

    case "DESCRIPTION_CHANGE":
      newState = { ...state };
      formValidity = true;

      // Set formvalidity to false if the length of the image-array is 0, if the title is not valid or if the incomning action is invalid.
      if (
        state.inputs.images.length === 0 ||
        !state.inputs.title.isValid ||
        !action.isValid
      ) {
        formValidity = false;
      }

      // Set the incoming value in state
      newState.inputs.description.value = action.value;
      newState.inputs.description.isValid = action.isValid;

      newState.isValid = formValidity;
      return newState;

    case "INGREDIENT_AMOUNT_OR_UNIT_CHANGE":
      newState = { ...state };
      formValidity = true;

      // Make the formstate invalid if the incoming action is not valid
      if (!action.isValid) {
        formValidity = false;
      }

      // Iterate through instructions array
      newState.inputs.instructions.forEach((instruction, instructionIndex) => {
        // Find the intruction with the right ID
        if (
          JSON.stringify(instruction.id) ===
          JSON.stringify(action.instructionId)
        ) {
          // Iterate through the array of ingredients and look for a match of ingredient id:s
          instruction.ingredients.forEach((ingredient, ingredientIndex) => {
            if (
              JSON.stringify(ingredient.id) ===
              JSON.stringify(action.ingredientId)
            ) {
              if (action.inputId === "quantity") {
                // Set value
                newState.inputs.instructions[instructionIndex].ingredients[
                  ingredientIndex
                ].quantity.value = action.value;
                // Set isValid
                newState.inputs.instructions[instructionIndex].ingredients[
                  ingredientIndex
                ].quantity.isValid = action.isValid;

                // Check if the validity of name and unit is valid
                if (
                  !ingredient.name.isValid ||
                  !ingredient.unit.isValid ||
                  ingredient.ingredientDbId.value === ""
                ) {
                  formValidity = false;
                }
              }
              if (action.inputId === "unit") {
                // Set Value
                newState.inputs.instructions[instructionIndex].ingredients[
                  ingredientIndex
                ].unit.value = action.value;

                // Set validity to true if unit is "efter smak"
                if (action.value === "efter smak") {
                  newState.inputs.instructions[instructionIndex].ingredients[
                    ingredientIndex
                  ].quantity.isValid = true;
                } else {
                  if (ingredient.quantity.value === "") {
                    newState.inputs.instructions[instructionIndex].ingredients[
                      ingredientIndex
                    ].quantity.isValid = false;
                  }
                }

                // Set isValid
                newState.inputs.instructions[instructionIndex].ingredients[
                  ingredientIndex
                ].unit.isValid = action.isValid;

                // Check if the validity of name and quantity is valid
                if (
                  !ingredient.name.isValid ||
                  !ingredient.quantity.isValid ||
                  ingredient.ingredientDbId.value === ""
                ) {
                  formValidity = false;
                }
              }
            } else {
              // Check the validity of the other ingredients, quantitys and units in the form
              if (
                !ingredient.name.isValid ||
                !ingredient.quantity.isValid ||
                !ingredient.unit.isValid
              ) {
                formValidity = false;
              }
            }
          });
        } else {
          // Check the validity of the other instructiontexts in the state
          if (instruction.text && !instruction.text.isValid) {
            formValidity = false;
          }
          //Also check validity of other sections
          if (instruction.section && !instruction.section.isValid) {
            formValidity = false;
          }
        }
      });

      newState.isValid = formValidity;
      return newState;

    case "INPUT_CHANGE":
      formIsValid = true;

      // state.inputs is all the keyes in the initial object
      for (const inputId in state.inputs) {
        if (!state.inputs[inputId]) {
          continue;
        }
        if (inputId === action.inputId) {
          formIsValid = formIsValid && action.isValid;
        } else {
          formIsValid = formIsValid && state.inputs[inputId].isValid;
        }
      }
      return {
        ...state,
        inputs: {
          ...state.inputs,
          [action.inputId]: { value: action.value, isValid: action.isValid },
        },
        isValid: formIsValid,
      };

    case "SET_TITLE_DESCRIPTION_IMAGE_VIDEO":
      newState = { ...state };
      formValidity = action.formIsValid;

      newState.inputs = action.inputs;

      newState.isValid = formValidity;

      return newState;

    case "SET_INSTRUCTION":
      newState = { ...state };
      formValidity = action.formIsValid;

      newState.inputs.instructions = newState.inputs.instructions.concat(
        action.inputs.instructions
      );

      newState.isValid = formValidity;

      return newState;

    case "CREATE_INGREDIENT":
      newState = { ...state };
      formValidity = action.formIsValid;

      // Iterate through the instructionsarray until it finds the instruction with the right instructionId
      newState.inputs.instructions.forEach((instruction, instructionIndex) => {
        // If the instructionId matches a ID in the state insert the newly created ingredient to the list
        if (
          JSON.stringify(instruction.id) ===
          JSON.stringify(action.instructionId)
        ) {
          newState.inputs.instructions[instructionIndex].ingredients =
            newState.inputs.instructions[instructionIndex].ingredients.concat([
              action.inputs,
            ]);
        }
      });
      newState.isValid = formValidity;
      return newState;

    case "SET_INGREDIENT":
      newState = { ...state };
      formValidity = true;

      if (!action.formIsValid) {
        formValidity = false;
      }

      // Iterate through instructions array
      newState.inputs.instructions.forEach((instruction, instructionIndex) => {
        // Find the intruction with the right ID
        if (
          JSON.stringify(instruction.id) ===
          JSON.stringify(action.instructionId)
        ) {
          // Iterate through the array of ingredients and look for a match of ingredient id:s
          instruction.ingredients.forEach((ingredient, ingredientIndex) => {
            if (
              JSON.stringify(ingredient.id) ===
              JSON.stringify(action.ingredientId)
            ) {
              // set the incoming name and the ingredientDbId in the state
              newState.inputs.instructions[instructionIndex].ingredients[
                ingredientIndex
              ].ingredientDbId.value = action.inputs.ingredientDbId.value;
              newState.inputs.instructions[instructionIndex].ingredients[
                ingredientIndex
              ].name.value = action.inputs.name.value;
              // Set isValid to true
              newState.inputs.instructions[instructionIndex].ingredients[
                ingredientIndex
              ].name.isValid = true;

              // Check the validity of the quantity and unit of the ingredient that is being added
              if (!ingredient.quantity.isValid || !ingredient.unit.isValid) {
                formValidity = false;
              }
            } else {
              // Check the validity of the other ingredients, quantitys and units in the form
              if (
                !ingredient.name.isValid ||
                !ingredient.quantity.isValid ||
                !ingredient.unit.isValid
              ) {
                formValidity = false;
              }
            }
          });
        } else {
          // Check the validity of the other instructiontexts in the state
          if (instruction.text && !instruction.text.isValid) {
            formValidity = false;
          }
          //Also check validity of other sections
          if (instruction.section && !instruction.section.isValid) {
            formValidity = false;
          }
        }
      });

      newState.isValid = formValidity;
      return newState;

    case "SET_IMAGE":
      newState = { ...state };
      formValidity = true;

      // Set formvalidity to false if "title" is invalid, if description is not valid or if incoming action is invalid
      if (
        !state.inputs.title.isValid ||
        !state.inputs.description.isValid ||
        !action.formIsValid
      ) {
        formValidity = false;
      }
      // Insert the image array into state
      newState.inputs.images = action.inputs.images;

      newState.isValid = formValidity;
      return newState;

    case "SET_VIDEO":
      newState = { ...state };
      formValidity = true;

      // Set formvalidity to false if "title" is invalid, if the image array is empty or if incoming action is not valid
      if (
        !state.inputs.title.isValid ||
        state.inputs.images.length === 0 ||
        !action.formIsValid
      ) {
        formValidity = false;
      }
      // Add the video object
      newState.inputs.video.value = action.inputs.video;
      newState.inputs.video.isValid = true;

      newState.isValid = formValidity;
      return newState;

    case "DELETE_IMAGE":
      newState = { ...state };
      formValidity = true;

      // Set the formValidity to false if the last image is removed from state
      if (state.inputs.images.length < 2) {
        formValidity = false;
      }
      // get index of the matching imageId
      const imageIndex = newState.inputs.images
        .map(function (item) {
          return item;
        })
        .indexOf(action.imageId);

      // remove image
      newState.inputs.images.splice(imageIndex, 1);

      newState.isValid = formValidity;
      return newState;

    case "DELETE_VIDEO":
      newState = { ...state };

      // Remove the video
      newState.inputs.video.value = "";
      newState.inputs.video.isValid = false;

      return newState;

    case "DELETE_INSTRUCTION":
      newState = { ...state };
      // get index of the matching instructionId
      instructionIndex = newState.inputs.instructions
        .map(function (item) {
          return item.id;
        })
        .indexOf(action.instructionId);

      // remove instruction
      newState.inputs.instructions.splice(instructionIndex, 1);

      newState.isValid = true;
      return newState;

    case "DELETE_INGREDIENT":
      newState = { ...state };
      // get index of the matching instructionId
      instructionIndex = newState.inputs.instructions
        .map(function (item) {
          return item.id;
        })
        .indexOf(action.instructionId);

      // get index of the matching instructionId
      ingredientIndex = newState.inputs.instructions[
        instructionIndex
      ].ingredients
        .map(function (item) {
          return item.id;
        })
        .indexOf(action.ingredientId);

      // remove ingredient
      newState.inputs.instructions[instructionIndex].ingredients.splice(
        ingredientIndex,
        1
      );

      // TODO: Check validity of the instruction and all ingredients in this instruction
      formValidity = true;

      if (!newState.inputs.instructions[instructionIndex].text.isValid) {
        formValidity = false;
      } else if (
        newState.inputs.instructions[instructionIndex].ingredients &&
        0 < newState.inputs.instructions[instructionIndex].ingredients.length
      ) {
        newState.inputs.instructions[instructionIndex].ingredients.map(
          (ingredient, index) => {
            if (
              !ingredient.name.isValid ||
              ingredient.ingredientDbId.value === "" ||
              !ingredient.quantity.isValid ||
              !ingredient.unit.isValid
            ) {
              formValidity = false;
            }
          }
        );
      }
      newState.isValid = formValidity;
      return newState;

    case "SET_DATA":
      return {
        inputs: action.inputs,
        isValid: action.formIsValid,
      };

    case "SET_CATEGORIES":
      newState = { ...state };
      newState.inputs.category = action.inputs.category;
      newState.inputs.cuisine = action.inputs.cuisine;
      newState.inputs.weekend = action.inputs.weekend;
      newState.inputs.portions = action.inputs.portions;
      newState.inputs.time = action.inputs.time;

      newState.isValid = true;
      return newState;

    case "MOVE_INSTRUCTION":
      newState = { ...state };
      newState.instructions = action.inputs.instructions;
      return newState;

    default:
      return state;
  }
};

export const useForm = (initialInputs, initialFormValidity) => {
  const [formState, dispatch] = useReducer(formReducer, {
    //Store the value of inputs from all inputfields combined
    inputs: initialInputs,
    isValid: initialFormValidity,
  });

  const inputHandler = useCallback(
    (id, value, isValid, instructionId, ingredientId) => {
      if (id === "title") {
        dispatch({
          type: "TITLE_CHANGE",
          value: value,
          isValid: isValid,
        });
      } else if (id === "description") {
        dispatch({
          type: "DESCRIPTION_CHANGE",
          value: value,
          isValid: isValid,
        });
      } else if (id === "image") {
        dispatch({
          type: "ADD_IMAGE",
          value: value,
          isValid: isValid,
        });
      } else if (id === "video") {
        dispatch({
          type: "ADD_VIDEO",
          value: value,
          isValid: isValid,
        });
      } else if (instructionId && ingredientId) {
        dispatch({
          type: "INGREDIENT_AMOUNT_OR_UNIT_CHANGE",
          value: value,
          isValid: isValid,
          inputId: id,
          instructionId: instructionId,
          ingredientId: ingredientId,
        });
      } else if (instructionId) {
        dispatch({
          type: "INSTRUCTION_CHANGE_TEXT",
          value: value,
          isValid: isValid,
          inputId: id,
          instructionId: instructionId,
        });
      } else {
        dispatch({
          type: "INPUT_CHANGE",
          value: value,
          isValid: isValid,
          inputId: id,
        });
      }
    },
    []
  );

  const setFormData = useCallback(
    (inputData, formValidity, type, instructionId, ingredientId) => {
      if (type === "move_instruction") {
        dispatch({
          type: "MOVE_INSTRUCTION",
          inputs: inputData,
        });
      }

      if (type === "set_categories") {
        dispatch({
          type: "SET_CATEGORIES",
          inputs: inputData,
          formIsValid: formValidity,
        });
      }

      if (type === "set_title_description_image_video") {
        dispatch({
          type: "SET_TITLE_DESCRIPTION_IMAGE_VIDEO",
          inputs: inputData,
          formIsValid: formValidity,
        });
      } else if (type === "set_instruction") {
        dispatch({
          type: "SET_INSTRUCTION",
          inputs: inputData,
          formIsValid: formValidity,
        });
      } else if (type === "create_ingredient") {
        dispatch({
          type: "CREATE_INGREDIENT",
          inputs: inputData,
          formIsValid: formValidity,
          instructionId: instructionId,
        });
      } else if (type === "set_ingredient") {
        dispatch({
          type: "SET_INGREDIENT",
          inputs: inputData,
          formIsValid: formValidity,
          instructionId: instructionId,
          ingredientId: ingredientId,
        });
      } else if (type === "set_image") {
        dispatch({
          type: "SET_IMAGE",
          inputs: inputData,
          formIsValid: formValidity,
        });
      } else if (type === "set_video") {
        dispatch({
          type: "SET_VIDEO",
          inputs: inputData,
          formIsValid: formValidity,
        });
      } else if (type === "delete_image") {
        dispatch({
          type: "DELETE_IMAGE",
          imageId: instructionId,
        });
      } else if (type === "delete_video") {
        dispatch({
          type: "DELETE_VIDEO",
        });
      } else if (type === "delete_instruction") {
        dispatch({
          type: "DELETE_INSTRUCTION",
          instructionId: instructionId,
        });
      } else if (type === "delete_ingredient") {
        dispatch({
          type: "DELETE_INGREDIENT",
          instructionId: instructionId,
          ingredientId: ingredientId,
        });
      } else {
        dispatch({
          type: "SET_DATA",
          inputs: inputData,
          formIsValid: formValidity,
        });
      }
    },
    []
  );

  return [formState, inputHandler, setFormData];
};
