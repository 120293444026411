import { MoreHoriz } from "@mui/icons-material";
import React from "react";
import { Link } from "react-router-dom";

import "./RecipeSummaryItem.css";

const RecipeSummaryItem = (props) => {
  let value;
  let sectionCount = 0;

  if (
    props.value === null ||
    props.value === undefined ||
    props.value === "" ||
    props.value.length < 1
  ) {
    value = (
      <span className="recipe-summary-item__value--missing">Ej angivet</span>
    );
  } else if (props.type === "image") {
    value = (
      <div className="file-preview">
        {props.value.map((image, index) => (
          <img src={image} alt="preview" key={index} />
        ))}
      </div>
    );
  } else if (props.type === "video") {
    if (props.value) {
      value = (
        <div className="file-preview">
          <video alt="preview">
            <source src={props.value} type="video/mp4" />
          </video>
        </div>
      );
    }
  } else if (props.type === "instructions") {
    value = (
      <ul>
        {props.value.map((item, index) => {
          if (item.section) {
            sectionCount++;
            return (
              <li key={item.id}>
                <div className="recipe-summary-item__label">Rubrik</div>
                {item.section.value}
              </li>
            );
          } else {
            return (
              <li key={item.id}>
                <div className="recipe-summary-item__label">{`Steg ${
                  index + 1 - sectionCount
                }`}</div>
                <div style={{ flexDirection: "column" }}>
                  {item.text.value}
                  {item.ingredients && item.ingredients.length > 0 && (
                    <div className="ingredients-string">
                      {item.ingredients
                        .map((ingredient) => {
                          let ingredientString = "";

                          if (ingredient.name) {
                            ingredientString += `${ingredient.name.value} `;
                            if (!ingredient.name.value) {
                              ingredientString += "INGREDIENS SAKNAS ";
                            }
                          }
                          if (
                            ingredient.quantity &&
                            ingredient?.unit.value !== "efter smak"
                          ) {
                            ingredientString += `${ingredient.quantity.value} `;
                            if (!ingredient.quantity.value) {
                              ingredientString += "MÄNGD SAKNAS ";
                            }
                          }
                          if (ingredient.unit) {
                            ingredientString += `${ingredient.unit.value}`;
                            if (!ingredient.unit.value) {
                              ingredientString += "MÅTTENHET SAKNAS ";
                            }
                          }

                          return ingredientString;
                        })
                        .join(", ")}
                    </div>
                  )}
                </div>
              </li>
            );
          }
        })}
      </ul>
    );
  } else if (props.type === "weekend") {
    // Check if weekend is undefined true or false
    if (props.value === true) {
      value = "Helgmåltid";
    }
    if (props.value === false) {
      value = "Vardagsmåltid";
    }
  } else if (props.type === "filter" || props.type === "completeMeal") {
    // Toggle value of props.value if props.toggleValue is true
    if (props.toggleValue) {
      value = props.value ? "Nej" : "Ja";
    } else {
      value = props.value ? "Ja" : "Nej";
    }
  } else if (props.type === "tags") {
    // Return string of tags separated by comma
    value = props.value.map((tag, index) => {
      if (index !== props.value.length - 1) {
        return `${tag}, `;
      } else {
        return `${tag}`;
      }
    });
  } else if (props.type === "portions") {
    value = `${props.value} portioner`;
  } else if (props.type === "time") {
    value = `${props.value} minuter`;
  } else {
    value = props.value;
  }

  return (
    <li className="recipe-summary-item">
      <hr className="recipe-summary-item__line" />
      <div className="recipe-summary-item__label">{props.label}</div>
      <div className="recipe-summary-item__value">
        <div>{value}</div>
        <Link to={`/recipe/${props.rid}/step-${props.step}`}>
          <MoreHoriz className="recipe-more-icon" />
        </Link>
      </div>
    </li>
  );
};

export default RecipeSummaryItem;
