import "./Hero.css";
import videoBgDesktop from "../../shared/assets/macaronies-desktop.mp4";
import videoBgPhone from "../../shared/assets/macaronies-phone.mp4";
import posterBgDesktop from "../../shared/assets/desktop-poster.png";
import posterBgPhone from "../../shared/assets/phone-poster.png";
import kurrLogo from "../../shared/assets/kurr-logo_v2_white.svg";
import { Helmet } from "react-helmet";

import { useRef, useState, useEffect } from "react";

import Button from "../../shared/components/FormElements/Button";
import linkNewTabIcon from "../../shared/assets/link_new-tab_material-design.svg";
import appStoreBadge from "../../shared/assets/app-store-badge.svg";
import googlePlayBadge from "../../shared/assets/google-play-badge.svg";
import appStoreBadges from "../../shared/assets/app-store-badges.svg";

const Hero = () => {
  const videoRef = useRef();
  const [videoUrl, setVideoUrl] = useState(videoBgDesktop);
  const [bgPoster, setBgPoster] = useState(posterBgDesktop);

  /**
   * Reload the video when the video source changes.
   */
  useEffect(() => {
    videoRef.current?.load();
  }, [videoUrl]);

  /**
   * Change the video source depending on the screen size.
   */
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 670) {
        setVideoUrl(videoBgPhone);
        setBgPoster(posterBgPhone);
      } else {
        setVideoUrl(videoBgDesktop);
        setBgPoster(posterBgDesktop);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onClick = () => {
    window.open(
      "https://kurr.typeform.com/to/HHZHT0DF",
      "_blank",
      "noreferrer"
    );
  };

  return (
    <div className="hero">
      <Helmet>
        <title>Kurr - AI-baserad Matapp känd från Draknästet</title>
        <meta
          name="description"
          content="Kurr är en AI-baserad matapp som nyligen presenterades i Draknästet. Ladda ner appen nu!"
        />
      </Helmet>
      <div className="hero__overlay">
        <div className="hero__overlay--full" />
        <div className="hero__overlay--top-gradient" />
        <div className="hero__overlay--bottom-gradient" />
      </div>
      <video
        className="hero__video"
        ref={videoRef}
        src={videoUrl}
        poster={bgPoster}
        autoPlay
        loop
        muted
        playsInline
      />
      <div className="hero__content">
        <img className="hero__content-logo" src={kurrLogo} alt="Kurr logo" />
        {/* <h1>Kurr. Svajpa, matcha, käka!</h1> */}
        {/* <div className="hero__content-button">
          <Button onClick={onClick}>
            Pre-access <img src={linkNewTabIcon} alt="link" />
          </Button>
        </div> */}
      </div>
      <div className="hero__app-store">
        <div className="hero_app-store--heading">SKAFFA APPEN</div>
        <div className="hero__app-store__icons">
          <img
            className="hero_app-store__icons--size"
            src={appStoreBadge}
            alt="App Store"
            onClick={() => {
              window.open(
                "https://apps.apple.com/se/app/kurr/id6449964264?platform=iphone",
                "_blank",
                "noreferrer"
              );
            }}
          />
          <img
            className="hero_app-store__icons--size"
            src={googlePlayBadge}
            alt="Google Play"
            onClick={() => {
              window.open(
                "https://play.google.com/store/apps/details?id=app.kurr.play",
                "_blank",
                "noreferrer"
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
