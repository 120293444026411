import React, { Fragment, useEffect, useState, useContext } from "react";

import RecipesList from "../components/RecipesList";
import Card from "../../shared/components/UIElements/Card";
import RecipeUploadHeader from "../components/progressBar/RecipeUploadHeader";
import { AuthContext } from "../../shared/context/auth-context";
import { useHttpClient } from "../../shared/hooks/http-hook";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import Button from "../../shared/components/FormElements/Button";
import Toggle from "../../shared/components/FormElements/Toggle";
import Dropdown from "../../shared/components/FormElements/Dropdown";

import { useHistory } from "react-router-dom";
import "./Recipes.css";

const Recipes = () => {
  const { user } = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const history = useHistory();

  // TODO: Filter and sort should be stored in context or redux
  const [allRecipes, setAllRecipes] = useState([]);
  const [loadedRecipes, setLoadedRecipes] = useState([]);
  const [autoUploadedRecipes, setAutoUploadedRecipes] = useState(false);
  const [containsWarnings, setContainsWarnings] = useState(false);
  const [containsErrors, setContainsErrors] = useState(false);
  const [sortOrder, setSortOrder] = useState("newestFirst");
  const [creatorOptions, setCreatorOptions] = useState([]);
  const [selectedCreator, setSelectedCreator] = useState("");
  const [creatorView, setCreatorView] = useState(false);
  const [creatorDetails, setCreatorDetails] = useState({
    email: "",
    name: "",
  });

  useEffect(() => {
    const fetchRecipes = async () => {
      try {
        const endpoint = user.isAdmin
          ? "/api/recipes/all"
          : "/api/recipes/user";
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + endpoint
        );
        setAllRecipes(responseData.recipes);
        setLoadedRecipes(responseData.recipes);
      } catch (err) {
        // TODO: Handle error
      }
    };

    fetchRecipes();
  }, [sendRequest, user.isAdmin]);

  // Collect all creators
  useEffect(() => {
    const creatorOptions = allRecipes.reduce((acc, recipe) => {
      const creator = recipe.creator;
      if (!acc.some((option) => option.value === creator._id)) {
        acc.push({ value: creator._id, label: creator.username });
      }
      return acc;
    }, []);
    setCreatorOptions(creatorOptions);
  }, [allRecipes]);

  // Filter and sort recipes
  useEffect(() => {
    let processedRecipes = [...allRecipes];

    // Filter logic
    if (autoUploadedRecipes) {
      processedRecipes = processedRecipes.filter(
        (recipe) => recipe.autoUpload.isAutoUploaded
      );
    }

    if (containsWarnings) {
      if (user.isAdmin && !creatorView) {
        processedRecipes = processedRecipes.filter(
          (recipe) => recipe.autoUpload.warnings.current.length > 0
        );
        // Creators should only be notified on missing ingredientDbId warnings
      } else {
        processedRecipes = processedRecipes.filter((recipe) =>
          recipe.autoUpload.warnings.current.some((warning) => {
            return warning.message === "ingredientDbId is null or undefined";
          })
        );
      }
    }

    if (containsErrors) {
      if (user.isAdmin && !creatorView) {
        processedRecipes = processedRecipes.filter((recipe) =>
          recipe.autoUpload.warnings.current.some((warning) => {
            return warning.level === "ERROR";
          })
        );
      }
    }

    if (selectedCreator) {
      processedRecipes = processedRecipes.filter(
        (recipe) => recipe.creator._id === selectedCreator
      );
      if (processedRecipes.length > 0) {
        setCreatorDetails({
          email: processedRecipes[0].creator.email,
          name: processedRecipes[0].creator.name,
        });
      }
    }

    setLoadedRecipes(processedRecipes);

    // Sorting logic
    if (sortOrder === "newestFirst") {
      processedRecipes.sort((a, b) => {
        const aDate = a.createdAt ? new Date(a.createdAt) : null;
        const bDate = b.createdAt ? new Date(b.createdAt) : null;
        if (!aDate && !bDate) return 0;
        if (!aDate) return 1;
        if (!bDate) return -1;
        return bDate - aDate;
      });
    } else if (sortOrder === "warningsCount") {
      processedRecipes.sort(
        (a, b) =>
          (b.autoUpload?.warnings.current?.length || -1) -
          (a.autoUpload?.warnings.current?.length || -1)
      );
    } else {
      // Default sort order is oldest first
      processedRecipes.sort((a, b) => {
        const aDate = a.createdAt ? new Date(a.createdAt) : null;
        const bDate = b.createdAt ? new Date(b.createdAt) : null;
        if (!aDate && !bDate) return 0;
        if (!aDate) return -1;
        if (!bDate) return 1;
        return aDate - bDate;
      });
    }

    setLoadedRecipes(processedRecipes);
  }, [
    autoUploadedRecipes,
    containsWarnings,
    containsErrors,
    creatorView,
    user.isAdmin,
    sortOrder,
    selectedCreator,
    allRecipes,
  ]);

  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
  };

  // Creates a new recipe in the database and links the user to update the first values
  const newRecipeSubmitHandler = async (event) => {
    event.preventDefault();

    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/api/recipes/new",
        "POST",
        JSON.stringify({
          creator: user.id,
        }),
        { "Content-Type": "application/json" }
      );

      // Redirect user to next page
      history.push(`/recipe/${responseData.recipe.id}/step-1`);
    } catch (err) {}
  };

  let dropdownOptions = [
    { value: "oldestFirst", label: "Äldst först" },
    { value: "newestFirst", label: "Nyast först" },
  ];
  if (user.isAdmin) {
    dropdownOptions.push({ value: "warningsCount", label: "Antal varningar" });
  }

  return (
    <Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && <LoadingSpinner asOverlay />}
      {!isLoading && loadedRecipes && (
        <Card className="recipe-summary">
          {!user.isAdmin && allRecipes.length < 1 ? (
            <Fragment>
              <RecipeUploadHeader
                hideBackButton={true}
                currentStep={null}
                title={"Välkommen " + user.name.split(" ")[0] + "!"}
              />
              <div className="no-recipes">
                <p>Klicka på knappen nedan för att skapa ditt första recept.</p>
              </div>
            </Fragment>
          ) : (
            <div>
              <RecipeUploadHeader
                hideBackButton={true}
                currentStep={null}
                title="Mina recept"
              />
              <div className="filters-container">
                {user.isAdmin && (
                  <Toggle
                    label="Kreatörsvy"
                    isActive={creatorView}
                    onToggle={() => setCreatorView(!creatorView)}
                  />
                )}
                <Toggle
                  label="Automatiskt uppladdade"
                  isActive={autoUploadedRecipes}
                  onToggle={() => setAutoUploadedRecipes(!autoUploadedRecipes)}
                />
                {user.isAdmin && (
                  <Toggle
                    label="Visa endast recept med varningar"
                    isActive={containsWarnings}
                    onToggle={() => setContainsWarnings(!containsWarnings)}
                  />
                )}
                {user.isAdmin && (
                  <Toggle
                    label="Visa endast recept med error"
                    isActive={containsErrors}
                    onToggle={() => setContainsErrors(!containsErrors)}
                  />
                )}
              </div>
              <Dropdown
                id="sortOrder"
                label="Sortera efter"
                value={sortOrder}
                onChange={handleSortChange}
                options={dropdownOptions}
              />
              {user.isAdmin && (
                <Dropdown
                  id="creatorFilter"
                  label="Kreatör"
                  value={selectedCreator}
                  onChange={(e) => setSelectedCreator(e.target.value)}
                  options={[{ value: "", label: "Alla" }, ...creatorOptions]}
                />
              )}
              {user.isAdmin && selectedCreator && (
                <div className="creator-details">
                  <span className="creator-heading">{creatorDetails.name}</span>
                  <br />
                  <span className="creator-list-item">
                    {creatorDetails.email}
                  </span>
                </div>
              )}

              <RecipesList
                items={loadedRecipes}
                isAdmin={user.isAdmin && !creatorView}
              />
            </div>
          )}

          <div className="button-bottom">
            <Button onClick={newRecipeSubmitHandler} type="submit" center>
              Nytt recept
            </Button>
          </div>
        </Card>
      )}
    </Fragment>
  );
};

export default Recipes;
