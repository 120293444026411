import React, { Fragment, useContext, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { HighlightOff } from "@mui/icons-material";

import Card from "../../../shared/components/UIElements/Card";
import RecipeUploadHeader from "../../components/progressBar/RecipeUploadHeader";
import Input from "../../../shared/components/FormElements/Input";
import {
  VALIDATOR_MAXLENGTH,
  VALIDATOR_MINLENGTH,
} from "../../../shared/util/validators";
import { useForm } from "../../../shared/hooks/form-hook";
import FileUpload from "../../../shared/components/FormElements/FileUpload";
import Button from "../../../shared/components/FormElements/Button";
import ErrorModal from "../../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../../shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from "../../../shared/context/auth-context";
import "./TitleAndDescription.css";

const TitleAndDescription = () => {
  const { user } = useContext(AuthContext);
  const recipeId = useParams().rid;
  const [recipeLoaded, setRecipeLoaded] = useState(false);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const history = useHistory();

  const [formState, inputHandler, setFormData] = useForm(
    {
      title: {
        value: "",
        isValid: false,
      },
      description: {
        value: "",
        isValid: true,
      },
      images: [],
      video: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  useEffect(() => {
    let formValidity = true;
    let titleValidity = true;
    let imageValidity = true;
    let videoValidity = true;
    const fetchRecipe = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/api/recipes/${recipeId}`
        );

        // If the response has a title and image the formvalidity should be true
        if (responseData.recipe.title === "") {
          titleValidity = false;
        }
        if (responseData.recipe.images.length < 1) {
          imageValidity = false;
        }
        if (
          responseData.recipe.video == null ||
          responseData.recipe.video === ""
        ) {
          videoValidity = false;
        }
        formValidity = titleValidity && imageValidity;

        setFormData(
          {
            title: {
              value: responseData.recipe.title,
              isValid: titleValidity,
            },
            description: {
              value: responseData.recipe.description,
              isValid: true,
            },
            images: responseData.recipe.images,

            video: {
              value: responseData.recipe.video,
              isValid: videoValidity,
            },
          },
          formValidity,
          "set_title_description_image_video"
        );

        setRecipeLoaded(true);
      } catch (err) {}
    };

    fetchRecipe();
  }, [recipeId, sendRequest, setFormData]);

  const uploadTitleAndDescriptionHandler = async (routing) => {
    try {
      const formData = new FormData();
      formData.append("title", formState.inputs.title.value);
      formData.append("description", formState.inputs.description.value);

      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/api/recipes/${recipeId}`,
        "PATCH",
        formData
      );

      if (routing === "forward") {
        history.push(`/recipe/${recipeId}/step-2`);
      } else if (routing === "back") {
        history.push("/recipes");
      }
    } catch (err) {}
  };

  const uploadImageHandler = async (image, fileIsValid) => {
    if (fileIsValid) {
      try {
        const formData = new FormData();
        formData.append("image", image);

        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL +
            `/api/recipes/${recipeId}/upload/image`,
          "POST",
          formData
        );

        setFormData(
          {
            images: responseData.recipe.images,
          },
          fileIsValid,
          "set_image"
        );
      } catch (err) {}
    }
  };

  const uploadVideoHandler = async (video, fileIsValid) => {
    if (fileIsValid) {
      try {
        const formData = new FormData();
        formData.append("video", video);

        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL +
            `/api/recipes/${recipeId}/upload/video`,
          "POST",
          formData
        );

        setFormData(
          {
            video: responseData.recipe.video,
          },
          fileIsValid,
          "set_video"
        );
      } catch (err) {}
    }
  };

  const deleteImageHandler = async (image) => {
    const encodedImg = encodeURIComponent(image);
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL +
          `/api/recipes/${recipeId}/delete/image/${encodedImg}`,
        "DELETE"
      );
      setFormData({}, true, "delete_image", image);
    } catch (err) {}
  };

  const deleteVideoHandler = async () => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL +
          `/api/recipes/${recipeId}/delete/video`,
        "DELETE"
      );
      setFormData({}, false, "delete_video");
    } catch (err) {}
  };

  const submitTitleAndDescriptionHandler = () => {
    uploadTitleAndDescriptionHandler("forward");
  };

  const stepBackHandler = () => {
    //uploadTitleAndDescriptionHandler("back");
    history.push("/recipes");
  };

  return (
    <Fragment>
      <ErrorModal error={error} onClear={clearError} />

      {isLoading && (
        <Card>
          <LoadingSpinner asOverlay />
        </Card>
      )}
      {!isLoading && recipeLoaded && (
        <Card className="description">
          <RecipeUploadHeader
            currentStep={1}
            title="Ladda upp recept"
            onBack={stepBackHandler}
          />
          <form>
            <Input
              element="input"
              id="title"
              type="text"
              placeholder=""
              label="Titel"
              validators={[VALIDATOR_MINLENGTH(1), VALIDATOR_MAXLENGTH(50)]}
              maxInput="50"
              errorText=""
              charCounter={true}
              onInput={inputHandler}
              initialValue={formState.inputs.title.value}
              initialValid={formState.inputs.title.isValid}
            />
            <Input
              element="input"
              id="description"
              type="text"
              placeholder=""
              label="Beskrivning"
              validators={[VALIDATOR_MAXLENGTH(560)]}
              maxInput="560"
              errorText=""
              charCounter={true}
              onInput={inputHandler}
              initialValue={formState.inputs.description.value}
              initialValid={formState.inputs.description.isValid}
            />

            <label htmlFor="image">Bilder</label>
            <div className="file-upload">
              {Object.keys(formState.inputs.images).length > 0 &&
                formState.inputs.images.map((img, index) => (
                  <div key={index} className="delete-container">
                    <HighlightOff
                      onClick={() => {
                        deleteImageHandler(img);
                      }}
                      fontSize="medium"
                      className="delete-ingredient-button"
                    />
                    <img key={img} src={img} alt="preview"></img>
                  </div>
                ))}
              <FileUpload
                name="images"
                element="input"
                id="image"
                label="Bilder"
                accept=".jpg,.png,.jpeg"
                onInput={inputHandler}
                uploadImage={uploadImageHandler}
              />
            </div>

            <label htmlFor="video">Video</label>
            <div className="file-upload">
              {formState.inputs.video.isValid && (
                <div className="delete-container">
                  <HighlightOff
                    onClick={deleteVideoHandler}
                    fontSize="medium"
                    className="delete-ingredient-button"
                  />
                  <video alt="preview">
                    <source
                      src={formState.inputs.video.value}
                      type="video/mp4"
                    />
                  </video>
                </div>
              )}
              {!formState.inputs.video.isValid && (
                <FileUpload
                  name="video"
                  element="input"
                  id="video"
                  accept="video/*"
                  onInput={inputHandler}
                  uploadVideo={uploadVideoHandler}
                />
              )}
            </div>
          </form>
          <div className="button-bottom">
            <Button
              onClick={submitTitleAndDescriptionHandler}
              type="button"
              center
              disabled={user.isAdmin ? false : !formState.isValid}
            >
              Nästa
            </Button>
          </div>
          <Button type="button" onClick={stepBackHandler} center inverse>
            Bakåt
          </Button>
        </Card>
      )}
    </Fragment>
  );
};

export default TitleAndDescription;
