import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import Button from "../../shared/components/FormElements/Button";
import Card from "../../shared/components/UIElements/Card";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "../../shared/hooks/http-hook";
import RecipeUploadHeader from "../components/progressBar/RecipeUploadHeader";
import RecipeSummaryItem from "../components/RecipeSummaryItem";
import "./RecipeSummary.css";
import dbToUploadFormConverter from "../../shared/util/dbToUploadFormConverter";

const RecipeSummary = () => {
  const history = useHistory();

  const recipeId = useParams().rid;
  const [loadedRecipe, setLoadedRecipe] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  useEffect(() => {
    const fetchRecipe = async () => {
      try {
        let responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/api/recipes/${recipeId}`
        );
        responseData.recipe.instructions = dbToUploadFormConverter(
          responseData.recipe.instructions
        );
        setLoadedRecipe(responseData.recipe);
      } catch (err) {}
    };
    fetchRecipe();
  }, [sendRequest, recipeId]);

  const uploadRecipeHandler = () => {
    history.push(`/recipe/${recipeId}/confirmation`);
  };

  // Function to call the backend to delete a recipe
  const deleteRecipeHandler = async () => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/api/recipes/${recipeId}`,
        "DELETE"
      );
      history.push("/");
    } catch (err) {}
  };

  return (
    <Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && (
        <Card>
          <LoadingSpinner asOverlay />
        </Card>
      )}
      {!isLoading && loadedRecipe && (
        <Card className="recipe-summary">
          <RecipeUploadHeader
            currentStep={4}
            title="Summering"
            hideBackButton={true}
          />
          <ul>
            <p
              onClick={() => {
                if (
                  window.confirm("Är du säker på att du vill radera receptet?")
                ) {
                  deleteRecipeHandler();
                }
              }}
              className="delete-recipe"
            >
              Radera recept
            </p>

            {/* Step 1 */}
            <RecipeSummaryItem
              type="title"
              label="Titel"
              value={loadedRecipe.title}
              rid={loadedRecipe.id}
              step={1}
            />
            <RecipeSummaryItem
              type="description"
              label="Beskrivning"
              value={loadedRecipe.description}
              rid={loadedRecipe.id}
              step={1}
            />
            <RecipeSummaryItem
              type="image"
              label="Bilder"
              value={loadedRecipe.images}
              rid={loadedRecipe.id}
              step={1}
            />
            <RecipeSummaryItem
              type="video"
              label="Video"
              value={loadedRecipe.video}
              rid={loadedRecipe.id}
              step={1}
            />
            {/* Step 2 */}
            <RecipeSummaryItem
              type="instructions"
              label="Instruktioner"
              value={loadedRecipe.instructions}
              rid={loadedRecipe.id}
              step={2}
            />
            {/* Step 3 */}
            <RecipeSummaryItem
              type="kitchen"
              label="Kök"
              value={loadedRecipe.cuisine}
              rid={loadedRecipe.id}
              step={3}
            />
            <RecipeSummaryItem
              type="category"
              label="Kategori"
              value={loadedRecipe.category}
              rid={loadedRecipe.id}
              step={3}
            />
            <RecipeSummaryItem
              type="weekend"
              label="Vardagsmåltid eller helgmåltid"
              value={loadedRecipe.weekend}
              rid={loadedRecipe.id}
              step={3}
            />
            <RecipeSummaryItem
              type="portions"
              label="Antal portioner"
              value={loadedRecipe.portions}
              rid={loadedRecipe.id}
              step={3}
            />
            <RecipeSummaryItem
              type="time"
              label="Tillagningstid"
              value={loadedRecipe.time}
              rid={loadedRecipe.id}
              step={3}
            />
            <RecipeSummaryItem
              type="completeMeal"
              label="Fullständing måltid"
              value={loadedRecipe.completeMeal}
              rid={loadedRecipe.id}
              step={3}
            />
            <RecipeSummaryItem
              type="filter"
              label="Vegetarisk"
              value={loadedRecipe.filter?.vegetarian}
              rid={loadedRecipe.id}
              step={3}
            />
            <RecipeSummaryItem
              type="filter"
              label="Vegansk"
              value={loadedRecipe.filter?.vegan}
              rid={loadedRecipe.id}
              step={3}
            />
            <RecipeSummaryItem
              type="filter"
              label="Innehåller nötter"
              value={loadedRecipe.filter?.nutFree}
              toggleValue={true}
              rid={loadedRecipe.id}
              step={3}
            />
            <RecipeSummaryItem
              type="filter"
              label="Innehåller laktos"
              value={loadedRecipe.filter?.dairyFree}
              toggleValue={true}
              rid={loadedRecipe.id}
              step={3}
            />
            <RecipeSummaryItem
              type="filter"
              label="Innehåller gluten"
              value={loadedRecipe.filter?.glutenFree}
              toggleValue={true}
              rid={loadedRecipe.id}
              step={3}
            />
            <RecipeSummaryItem
              type="tags"
              label="Taggar"
              value={loadedRecipe.tags}
              rid={loadedRecipe.id}
              step={3}
            />
            <hr className="recipe-summary-item__line" />
          </ul>
          <div className="button-bottom">
            <Button onClick={uploadRecipeHandler} type="button" center>
              Slutför
            </Button>
          </div>
        </Card>
      )}
    </Fragment>
  );
};

export default RecipeSummary;
