import React, { Fragment, useContext, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { AuthContext } from "../../context/auth-context";

import "./NavLinks.css";
import exitLogo from "../../assets/exit.svg";

import Hamburger from "../../../home/components/Hamburger";

const NavLinks = (props) => {
  const auth = useContext(AuthContext);
  const location = useLocation();

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const urlParams = new URLSearchParams(location.search);
  const isDeepLinkRoute =
    urlParams.has("recipeId") && urlParams.has("senderId");

  return (
    <ul
      className={`nav-links ${
        location.pathname === "/" ||
        location.pathname === "/app" ||
        location.pathname === "/menu" ||
        location.pathname === "/store" ||
        isDeepLinkRoute
          ? "nav-links__white"
          : "nav-links__black"
      }`}
    >
      {!auth.isLoggedIn && (
        <Fragment>
          {location.pathname === "/" ||
          "/terms-and-conditions" ||
          "/privacy-policy" ? (
            <li>
              <NavLink to="/creator">Kreatör</NavLink>
              <NavLink to="/menu">Kontakt</NavLink>
            </li>
          ) : null}
          {location.pathname === "/menu" ? (
            <div className="nav-links__exit">
              <li>
                <NavLink to="/">
                  <img src={exitLogo} alt="exit" />
                </NavLink>
              </li>
            </div>
          ) : null}
          {/*}
          <NavLink to={menuOpen ? "/" : "/menu"}>
            <Hamburger menuOpen={menuOpen} toggleMenu={toggleMenu} />
            
          </NavLink>
          */}
        </Fragment>
      )}
      {auth.isLoggedIn && (
        <li>
          <button onClick={auth.logout}>Logga ut</button>
        </li>
      )}
    </ul>
  );
};

export default NavLinks;
