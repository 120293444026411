import { newTracker, trackPageView } from "@snowplow/browser-tracker";

/**
 * Initialize the tracker.
 */
const initializeTracker = () => {
  newTracker("webTracker", "https://plogbilen.kurr.app", {
    appId: "KurrWeb",
    discoverRootDomain: true,
    cookieSameSite: "Lax", // Recommended
    contexts: {
      webPage: true, // default, can be omitted
      session: false,
      browser: true,
    },
  });

  // Send a page view event
  trackPageView();
};

const Tracker = {
  initializeTracker,
};

export default Tracker;
