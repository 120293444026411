import React, { Fragment, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Card from "../../../shared/components/UIElements/Card";
import { useForm } from "../../../shared/hooks/form-hook";
import RecipeUploadHeader from "../../components/progressBar/RecipeUploadHeader";
import Button from "../../../shared/components/FormElements/Button";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import ErrorModal from "../../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../../shared/components/UIElements/LoadingSpinner";
import { AuthContext } from "../../../shared/context/auth-context";
import "./Instructions.css";
import { useHistory } from "react-router-dom";
import "./Categorize.css";
import { InfoOutlined } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";

import Toggle from "../../../shared/components/FormElements/Toggle";
import Input from "../../../shared/components/FormElements/Input";
import Tags from "../../../shared/components/FormElements/Tags";
import {
  VALIDATOR_MAXLENGTH,
  VALIDATOR_MINLENGTH,
  VALIDATOR_MIN,
  VALIDATOR_MAX,
} from "../../../shared/util/validators";

const AdminTooltip = ({ tooltipText, label }) => (
  <div>
    <Tooltip
      title={<span style={{ whiteSpace: "pre-wrap" }}>{tooltipText}</span>}
      placement="right"
      interactive
    >
      <span
        style={{
          verticalAlign: "middle",
          display: "inline-flex",
          alignItems: "center",
          marginTop: "16px",
        }}
      >
        <InfoOutlined
          style={{ color: "var(--kurr-black25)", fontSize: "20px" }}
        />
        <span
          style={{
            marginLeft: "8px",
            fontFamily: "monospace",
            fontSize: "12px",
            fontWeight: "bold",
            color: "var(--kurr-black25)",
          }}
        >
          {label}
        </span>
      </span>
    </Tooltip>
  </div>
);

const Categorize = () => {
  const { user } = useContext(AuthContext);
  const history = useHistory();
  const recipeId = useParams().rid; // gets the recipe-id located in the url
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [completeMeal, toggleCompleteMeal] = useState(false);
  const [vegetarian, toggleVegetarian] = useState(false);
  const [vegan, toggleVegan] = useState(false);
  const [nuts, toggleNuts] = useState(true);
  const [diary, toggleDiary] = useState(true);
  const [gluten, toggleGluten] = useState(true);

  const [tags, setTags] = useState([]);
  const [tagsDisabled, setTagsDisabled] = useState(false);
  const [tagsIsValid, setTagsIsValid] = useState(false);

  const [initialRecipe, setInitialRecipe] = useState(true);

  const [formState, inputHandler, setFormData] = useForm(
    {
      cuisine: {
        value: "",
        isValid: false,
      },
      category: {
        value: "",
        isValid: false,
      },
      weekend: {
        value: false,
        isValid: false,
      },
      portions: {
        value: 4,
        isValid: true,
      },
      time: {
        value: 0,
        isValid: false,
      },
    },
    false
  );

  /**
   * Fetch recipe from database
   */
  useEffect(() => {
    const fetchRecipe = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/api/recipes/${recipeId}`
        );

        //Load states if they have been set before (if any state is null, all should have initial state value)
        if (responseData.recipe.completeMeal !== null) {
          setInitialRecipe(false);

          toggleCompleteMeal(responseData.recipe.completeMeal);
          toggleVegetarian(responseData.recipe.filter.vegetarian);
          toggleVegan(responseData.recipe.filter.vegan);
          toggleNuts(!responseData.recipe.filter.nutFree);
          toggleDiary(!responseData.recipe.filter.dairyFree);
          toggleGluten(!responseData.recipe.filter.glutenFree);
          setTags(responseData.recipe.tags);

          //Load formState
          setFormData(
            {
              category: {
                value: responseData.recipe.category,
                isValid: true,
              },
              cuisine: {
                value: responseData.recipe.cuisine,
                isValid: true,
              },
              weekend: {
                value:
                  responseData.recipe.weekend === true
                    ? "Helgmåltid"
                    : "Vardagsmåltid",
                isValid: true,
              },
              portions: {
                value: responseData.recipe.portions,
                isValid: true,
              },
              time: {
                value: responseData.recipe.time,
                isValid: true,
              },
            },
            true,
            "set_categories"
          );
        }
      } catch (err) {}
    };
    fetchRecipe();
  }, []);

  /**
   * Set if it should be possible to write tags input
   */
  const MIN_NR_OF_TAGS = 0;
  const MAX_NR_OF_TAGS = 5;

  useEffect(() => {
    // Disable input if max amount of tags is entered
    if (tags.length >= MAX_NR_OF_TAGS && tagsDisabled === false) {
      setTagsDisabled(true);
    } else {
      if (tagsDisabled === true) {
        setTagsDisabled(false);
      }
    }

    // Set tags as valid if min amount of tags is entered
    if (tags.length < MIN_NR_OF_TAGS) {
      setTagsIsValid(false);
    } else {
      setTagsIsValid(true);
    }
  }, [tags]);

  /**
   * Upload recipe to database and redirects to next page
   */
  const uploadCategorizeHandler = async (routing) => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/api/recipes/upload/categorize",
        "POST",
        JSON.stringify({
          recipeId: recipeId,
          filter: {
            vegetarian: vegetarian,
            vegan: vegan,
            dairyFree: !diary,
            nutFree: !nuts,
            glutenFree: !gluten,
          },
          category: formState.inputs.category.value,
          cuisine: formState.inputs.cuisine.value,
          portions: formState.inputs.portions.value,
          time: formState.inputs.time.value,
          weekend:
            formState.inputs.weekend.value === "Helgmåltid" ? true : false,
          completeMeal: completeMeal,
          tags: tags,
        }),
        { "Content-Type": "application/json" }
      );
      if (routing === "forward") {
        history.push(`/recipe/${recipeId}/step-4`);
      } else if (routing === "back") {
        history.push(`/recipe/${recipeId}/step-2`);
      }
    } catch (err) {}
  };

  /**
   * Toggles validity status of form to enable or disable next-button.
   */
  const setDisabled = () => {
    if (formState.isValid && tagsIsValid) {
      return false;
    } else {
      return true;
    }
  };

  const submitCategorizeHandler = () => {
    uploadCategorizeHandler("forward");
  };

  const stepBackHandler = () => {
    uploadCategorizeHandler("back");
  };

  /**
   * Waits for formState to be set before rendering if the recipes has previously been set.
   */
  const renderConditions = () => {
    if (!initialRecipe) {
      return !isLoading && formState.inputs.time.value !== 0;
    } else {
      return !isLoading;
    }
  };

  return (
    <Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {renderConditions() ? (
        <Card className="card">
          <RecipeUploadHeader
            currentStep={3}
            title="Kategorisera"
            onBack={stepBackHandler}
            onClick={uploadCategorizeHandler}
          />

          <form>
            {/* KÖK: SELECT */}
            <Input
              id="cuisine"
              initialValue={formState.inputs.cuisine.value}
              element="select"
              type="text"
              label="Kök"
              placeholder=""
              validators={[]}
              onInput={inputHandler}
              select={[
                "Husmanskost",
                "Afrika",
                "Amerika",
                "Asien",
                "Frankrike",
                "Indien",
                "Italien",
                "Japan",
                "Karibien",
                "Kina",
                "Korea",
                "Mellanöstern",
                "Mexiko",
                "Spanien",
                "Storbritannien",
                "Sydostasien",
                "Taiwan",
                "Thailand",
                "Tyskland",
                "Österrike",
                "Fusion food",
                "Övrigt",
              ]}
            />

            {/* KATEGORI: SELECT */}
            <Input
              id="category"
              initialValue={formState.inputs.category.value}
              element="select"
              type="text"
              label="Kategori"
              placeholder=""
              validators={[]}
              onInput={inputHandler}
              select={[
                "Bowl",
                "Fingerfood",
                "Gratäng",
                "Gryta",
                "Grillat",
                "Hamburgare",
                "Lasagne",
                "Paj",
                "Pannkaka",
                "Pasta",
                "Pizza",
                "Sallad",
                "Smörgås",
                "Soppa",
                "Tacos",
                "Våfflor",
                "Wok",
                "Övrigt",
              ]}
            />

            {/* VARDAG/HELG: SELECT */}
            <Input
              id="weekend"
              initialValue={formState.inputs.weekend.value}
              element="select"
              type="text"
              label="Vardagsmåltid eller helgmåltid"
              placeholder=""
              validators={[]}
              onInput={inputHandler}
              select={["Vardagsmåltid", "Helgmåltid"]}
            />

            {/* Portions: INPUT */}
            <Input
              id="portions"
              initialValue={formState.inputs.portions.value}
              formState={formState}
              setFormData={setFormData}
              element="input"
              type="number"
              placeholder=""
              label={"Antal portioner"}
              validators={[VALIDATOR_MINLENGTH(1), VALIDATOR_MIN(1)]}
              errorText="1-10"
              onInput={inputHandler}
              displayValidityStatus={false}
            />

            {/* TID: INPUT */}
            <Input
              id="time"
              initialValue={formState.inputs.time.value}
              formState={formState}
              setFormData={setFormData}
              element="input"
              type="number"
              placeholder=""
              label={"Tillagningstid (minuter)"}
              validators={[VALIDATOR_MINLENGTH(1)]}
              /*errorText="Kort beskrivning, max 500 tecken"*/
              onInput={inputHandler}
              displayValidityStatus={false}
              min={0}
            />

            <div className="categorize-separator"></div>
            {/* FULLSTÄNDIG: TOGGLE */}
            <Toggle
              id="completeMeal"
              label="Fullständig måltid"
              isActive={completeMeal}
              onToggle={() => toggleCompleteMeal(!completeMeal)}
            />

            {/* VEGETARISK: TOGGLE */}
            <Toggle
              label="Vegetarisk"
              isActive={vegetarian}
              onToggle={() => toggleVegetarian(!vegetarian)}
            />

            {/* VEGANSK: TOGGLE */}
            <Toggle
              label="Vegansk"
              isActive={vegan}
              onToggle={() => toggleVegan(!vegan)}
            />

            {/* NUTS: TOGGLE */}
            <Toggle
              label="Innehåller nötter"
              isActive={nuts}
              onToggle={() => toggleNuts(!nuts)}
            />

            {/* DIARY: TOGGLE */}
            <Toggle
              label="Innehåller laktos"
              isActive={diary}
              onToggle={() => toggleDiary(!diary)}
            />

            {/* GLUTEN: TOGGLE */}
            <Toggle
              label="Innehåller gluten"
              isActive={gluten}
              onToggle={() => toggleGluten(!gluten)}
            />

            <div className="categorize-separator"></div>

            <Tags tags={tags} setTags={setTags} disabled={tagsDisabled} />
          </form>

          <div className="button-bottom">
            <Button
              onClick={submitCategorizeHandler}
              type="submit"
              className="button-bottom"
              disabled={setDisabled()}
              center
            >
              Granska
            </Button>
          </div>
          <Button type="button" onClick={stepBackHandler} center inverse>
            Bakåt
          </Button>
        </Card>
      ) : (
        <Card>
          <LoadingSpinner asOverlay />
        </Card>
      )}
    </Fragment>
  );
};

export default Categorize;
