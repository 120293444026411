import React from "react";
import { Link, useLocation } from "react-router-dom";

import MainHeader from "./MainHeader";
import logo from "../../../shared/assets/Kurr-logo_v1_black.svg";
import logoWhite from "../../../shared/assets/macaroni_logo_white.svg";
import "./MainNavigation.css";
import NavLinks from "./NavLinks";

const MainNavigation = (props) => {
  const location = useLocation();

  const urlParams = new URLSearchParams(location.search);
  const isDeepLinkRoute =
    urlParams.has("recipeId") && urlParams.has("senderId");

  return (
    <MainHeader>
      <Link to="/">
        <img
          src={
            location.pathname === "/" ||
            location.pathname === "/app" ||
            location.pathname === "/menu" ||
            location.pathname === "/store" ||
            isDeepLinkRoute
              ? logoWhite
              : logo
          }
          alt="logo"
        />
      </Link>
      <nav>
        <NavLinks />
      </nav>
    </MainHeader>
  );
};

export default MainNavigation;
