import React, { useCallback, useState, useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import Auth from "./user/pages/Auth";
import AuthContextProvider, {
  AuthContext,
} from "./shared/context/auth-context";

import MainNavigation from "./shared/components/Navigation/MainNavigation";
import Hero from "./home/pages/Hero";
import Home from "./home/pages/Home";
import Menu from "./home/pages/Menu";
import Footer from "./shared/components/UIElements/Footer";
import Recipes from "./recipes/pages/Recipes";
import PrivacyPolicy from "./home/components/PrivacyPolicy";

import TitleAndDescription from "./recipes/pages/upload/TitleAndDescription";
import Instructions from "./recipes/pages/upload/Instructions";
import Categorize from "./recipes/pages/upload/Categorize";
import Confirmation from "./recipes/pages/upload/Confirmation";
import RecipeSummary from "./recipes/pages/RecipeSummary";
import Verify from "./user/pages/Verify";
import "./App.css";
import TermsAndConditions from "./home/pages/TermsAndConditions";
import MacaroniPolicy from "./home/pages/MacaroniPolicy";
import { TrackerProvider } from "./tracker/tracker-context";
import Recipe from "./home/pages/Recipe";
import RedirectToApp from "./home/pages/RedirectToApp";
import RedirectToStore from "./home/pages/RedirectToStore";

const Routes = () => {
  const { isLoggedIn, login } = useContext(AuthContext);

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      login(storedToken);
    }
  }, []);

  if (isLoggedIn) {
    return (
      <Switch>
        {/* Routed from universal links, this routes the user to the Kurr tab */}
        <Route path="/app" exact>
          <RedirectToApp />
        </Route>
        {/* Routed from universal links, this routes the user to the specific shared recipe */}
        <Route path="/recipe" exact>
          <Recipe />
        </Route>
        {/* Routed from universal links, this routes user to system app store */}
        <Route path="/store" exact>
          <RedirectToStore />
        </Route>

        {/* Routes for web app*/}
        <Route path="/:rid/recipe" exact>
          <RecipeSummary />
        </Route>
        <Route path="/recipe/step-1" exact>
          <TitleAndDescription />
        </Route>
        <Route path="/recipe/:rid/step-1" exact>
          <TitleAndDescription />
        </Route>
        <Route path="/recipe/:rid/step-2" exact>
          <Instructions />
        </Route>
        <Route path="/recipe/:rid/step-3" exact>
          <Categorize />
        </Route>
        <Route path="/recipe/:rid/step-4" exact>
          <RecipeSummary />
        </Route>
        <Route path="/recipe/:rid/confirmation" exact>
          <Confirmation />
        </Route>

        <Route path="/menu" exact>
          <Menu />
        </Route>
        <Route path="/recipes" exact>
          <Recipes />
        </Route>
        <Redirect to="/recipes" />
      </Switch>
    );
  } else {
    return (
      <Switch>
        {/* Routed from universal links, this routes the user to the Kurr tab */}
        <Route path="/app" exact>
          <RedirectToApp />
        </Route>
        {/* Routed from universal links, this routes the user to the specific shared recipe */}
        <Route path="/recipe" exact>
          <Recipe />
        </Route>
        {/* Routed from universal links, this routes user to system app store */}
        <Route path="/store" exact>
          <RedirectToStore />
        </Route>

        <Route path="/recipe" exact>
          <Recipe />
        </Route>
        <Route path="/:uid/verify" exact>
          <Verify />
        </Route>
        <Route path="/" exact>
          <Hero />
        </Route>
        <Route path="/menu" exact>
          <Menu />
        </Route>
        <Route path="/creator" exact>
          <Auth />
        </Route>
        <Route path="/privacy-policy" exact>
          <PrivacyPolicy />
        </Route>
        <Route path="/terms-and-conditions" exact>
          <TermsAndConditions />
        </Route>
        <Route path="/macaroni-policy" exact>
          <MacaroniPolicy />
        </Route>
        <Redirect to="/" />
      </Switch>
    );
  }
};

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userId, setUserId] = useState(false);

  //Usecallback makes sure that the function never has to be recreated
  const login = useCallback((uid) => {
    setIsLoggedIn(true);
    setUserId(uid);
  }, []);

  const logout = useCallback(() => {
    setIsLoggedIn(false);
    setUserId(null);
  }, []);

  // Handle routing based on login-state
  // let routes;

  return (
    <TrackerProvider>
      <AuthContextProvider>
        <div className="full-screen-container">
          <Router>
            <MainNavigation />
            <Routes />
            <Footer />
          </Router>
        </div>
      </AuthContextProvider>
    </TrackerProvider>
  );
};

export default App;
