import React, { useReducer, useEffect, Fragment, useRef } from "react";
import { Warning } from "@mui/icons-material";

import { validate } from "../../util/validators";
import "./Input.css";

const inputReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.val,
        isValid: validate(action.val, action.validators),
      };
    case "TOUCH":
      return {
        ...state,
        isTouched: true,
      };
    default:
      return state;
  }
};

const Input = (props) => {
  let charCounter = false;
  if (props.charCounter) {
    charCounter = true;
  }

  const [inputState, dispatch] = useReducer(inputReducer, {
    value: props.initialValue || "",
    isTouched: false,
    isValid: props.initialValid || false,
  });

  // Function to pass changes in the form back to "parent component"
  const { id, onInput } = props; //destructure to not end up in infinite loop
  const { instructionId, ingredientId } = props; // Get the specific id of the instruction and the id of the ingredient.
  const { value, isValid } = inputState;

  const isMounted = useRef(false);
  useEffect(() => {
    if (isMounted.current) {
      onInput(id, value, isValid, instructionId, ingredientId);
    } else {
      isMounted.current = true;
    }
  }, [id, onInput, value, isValid, instructionId, ingredientId]);

  const changeHandler = (event) => {
    //Handle number input fields
    if (props.type === "number") {
      // If the last character is a comma, replace it with a dot
      if (event.target.value.charAt(event.target.value.length - 1) === ",") {
        event.target.value = event.target.value.replace(",", ".");
      }

      //Remove all non-numeric characters but dot
      if (isNaN(event.target.value)) {
        event.target.value = event.target.value.replace(/[^0-9.,]/g, "");
      }

      //If event.target.value has decimals
      if (event.target.value % 1 !== 0) {
        //Remove any extra comma or dot
        if (
          event.target.value.charAt(event.target.value.length - 1) === "." ||
          event.target.value.charAt(event.target.value.length - 1) === ","
        ) {
          event.target.value = event.target.value.slice(0, -1);
        }
        //Limit to 2 decimals
        if (event.target.value.split(".")[1]?.length > 2) {
          event.target.value = event.target.value.slice(0, -1);
        }
      }
    }

    dispatch({
      type: "CHANGE",
      val: event.target.value,
      validators: props.validators,
    });
  };

  const touchHandler = () => {
    dispatch({
      type: "TOUCH",
    });
  };

  let element;
  if (props.element === "input") {
    element = (
      <input
        id={props.id}
        type={props.type === "number" ? "text" : props.type}
        placeholder={props.placeholder}
        onChange={changeHandler}
        onBlur={touchHandler}
        value={inputState.value}
        min={props.min}
        disabled={props.disabled}
      />
    );
  } else if (props.element === "select") {
    element = (
      <select
        id={props.id}
        type={props.type}
        onChange={changeHandler}
        onBlur={touchHandler}
        value={inputState.value}
      >
        <option hidden>{props.placeholder}</option>
        {props.select.map((unit) => (
          <option key={unit}>{unit}</option>
        ))}
      </select>
    );
  }

  return (
    <div
      className={`form-control ${
        !inputState.isValid && inputState.isTouched && "form-control--invalid"
      } ${props.style} font`}
    >
      <label htmlFor={props.id} style={{}}>
        {props.label}
        {props.warningIcon && (
          <span
            style={{
              verticalAlign: "middle",
              paddingLeft: "8px",
            }}
          >
            <Warning
              style={{
                color: `${"var(--ios-red)"}`,
                fontSize: "20px",
                position: "relative",
                top: "2px",
              }}
            />
          </span>
        )}
      </label>
      {element}

      {charCounter && (
        <div className="form-control__subheading--right input-subheading">
          <p>
            {props.errorText}
            <span className="form-control-medium-bold">
              {" (" + inputState.value.length + "/" + props.maxInput + ")"}
            </span>
          </p>
        </div>
      )}
    </div>
  );
};

export default Input;
