import React from 'react';
import "./Toggle.css";

const Toggle = (props) => {
  return(
    <div className='toggle-container'>
    <p>{props.label}</p>
      <label className="toggle">
        <input type="checkbox" checked={props.isActive} onChange={props.onToggle}/>
        <span className="slider" />
      </label>
    </div>
    );
};

export default Toggle;