import { React, useContext } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../../shared/context/auth-context";
import { useHttpClient } from "../../../shared/hooks/http-hook";

import Button from "../../../shared/components/FormElements/Button";
import "./Confirmation.css";
import Card from "../../../shared/components/UIElements/Card";
import RecipeUploadHeader from "../../components/progressBar/RecipeUploadHeader";
import { Fragment } from "react";

import confirmationImg from "../../../shared/assets/recipe-confirmation-img.svg";


const Confirmation = () => {
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();


  const newRecipeSubmitHandler = async (event) => {
    event.preventDefault();

    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/api/recipes/new",
        "POST",
        JSON.stringify({
          creator: user.id,
        }),
        { "Content-Type": "application/json" }
      );

      // Redirect user to next page
      history.push(`/recipe/${responseData.recipe.id}/step-1`);
    } catch (err) {}
  };

  

  return (
    <Fragment>
      <Card className="card">
      <RecipeUploadHeader currentStep={0} title="Ditt recept är nu uppladdat!" />
      <img className="confirmation-img" src={confirmationImg} alt="Confirmation"/>
        <div className="button-bottom">
          <Button onClick={newRecipeSubmitHandler} type="button" center>
            Ladda upp ytterligare recept
          </Button>
        </div>
        <Button onClick={() => {history.push("/recipes")}}type="button" center inverse>
          Alla mina recept
          </Button>
      </Card>
    </Fragment>
  );
};

export default Confirmation;