import "./Recipe.css";
import videoBgDesktop from "../../shared/assets/macaronies-desktop.mp4";
import videoBgPhone from "../../shared/assets/macaronies-phone.mp4";
import posterBgDesktop from "../../shared/assets/desktop-poster.png";
import posterBgPhone from "../../shared/assets/phone-poster.png";
import kurrLogo from "../../shared/assets/kurr-logo_v2_white.svg";
import { Helmet } from "react-helmet";

import { useRef, useState, useEffect } from "react";

import appStoreBadge from "../../shared/assets/app-store-badge.svg";
import googlePlayBadge from "../../shared/assets/google-play-badge.svg";
import Modal from "../../shared/components/UIElements/Modal";

const Recipe = () => {
  const videoRef = useRef();
  const [videoUrl, setVideoUrl] = useState(videoBgDesktop);
  const [bgPoster, setBgPoster] = useState(posterBgDesktop);
  const [showModal, setShowModal] = useState(false);

  /**
   * Try to open the app with fallback to app store
   * if the app is not installed
   */
  useEffect(() => {
    const tryOpenAppWithFallback = () => {
      const deepLink = `kurr://app`;
      window.location.href = deepLink;

      const fallbackTimeout = setTimeout(() => {
        // If the app is not opened in 2 seconds, route the user to the correct app store or show modal
        // openAppStore();
        setShowModal(true);
      }, 2000); // 2 seconds

      const clearFallback = () => {
        clearTimeout(fallbackTimeout);
        window.removeEventListener("blur", clearFallback);
      };

      window.addEventListener("blur", clearFallback);
    };

    tryOpenAppWithFallback();
  }, []);

  /**
   * Reload the video when the video source changes.
   */
  useEffect(() => {
    videoRef.current?.load();
  }, [videoUrl]);

  /**
   * Change the video source depending on the screen size.
   */
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 670) {
        setVideoUrl(videoBgPhone);
        setBgPoster(posterBgPhone);
      } else {
        setVideoUrl(videoBgDesktop);
        setBgPoster(posterBgDesktop);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  /**
   * Open the appropriate store based on the user's platform.
   */
  const openAppStore = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Check for iOS devices
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.open(
        "https://apps.apple.com/se/app/kurr/id6449964264?platform=iphone",
        "_blank",
        "noreferrer"
      );
    }
    // Check for Android devices
    else if (/android/i.test(userAgent)) {
      window.open(
        "https://play.google.com/store/apps/details?id=app.kurr.play",
        "_blank",
        "noreferrer"
      );
    }
    // Fallback for other devices or if unable to determine
    else {
      setShowModal(true); // Show the modal as fallback
    }
  };

  /**
   * Close modal
   */
  const closeModalHandler = () => {
    setShowModal(false);
  };

  return (
    <div className="hero">
      <Helmet>
        <meta
          property="og:image"
          content="https://kurr-images-bucket.s3.eu-north-1.amazonaws.com/kurr_link_logo.png"
        />
        <meta property="og:title" content="Kurr - Svajpa · Matcha · Käka!" />
      </Helmet>
      <div className="hero__overlay">
        <div className="hero__overlay--full" />
        <div className="hero__overlay--top-gradient" />
        <div className="hero__overlay--bottom-gradient" />
      </div>
      <video
        className="hero__video"
        ref={videoRef}
        src={videoUrl}
        poster={bgPoster}
        autoPlay
        loop
        muted
        playsInline
      />
      {showModal && (
        <div>
          <Modal
            onCancel={closeModalHandler}
            header="ÅHNEJ!"
            show={"INSTALLERA APPEN!"}
            footer={
              <div className="hero__app-store__icons--modal">
                <img
                  src={appStoreBadge}
                  alt="App Store"
                  onClick={() => {
                    window.open(
                      "https://apps.apple.com/se/app/kurr/id6449964264?platform=iphone",
                      "_blank",
                      "noreferrer"
                    );
                  }}
                />
                <img
                  src={googlePlayBadge}
                  alt="Google Play"
                  onClick={() => {
                    window.open(
                      "https://play.google.com/store/apps/details?id=app.kurr.play",
                      "_blank",
                      "noreferrer"
                    );
                  }}
                />
              </div>
            }
          >
            <p>{"Ladda ner Kurr för att börja svajpa recept 🧑‍🍳"}</p>
          </Modal>
        </div>
      )}
      <div className="hero__content">
        <img className="hero__content-logo" src={kurrLogo} alt="Kurr logo" />
      </div>
      <div className="hero__app-store">
        <div className="hero_app-store--heading">SKAFFA APPEN</div>
        <div className="hero__app-store__icons">
          <img
            className="hero_app-store__icons--size"
            src={appStoreBadge}
            alt="App Store"
            onClick={() => {
              window.open(
                "https://apps.apple.com/se/app/kurr/id6449964264?platform=iphone",
                "_blank",
                "noreferrer"
              );
            }}
          />
          <img
            className="hero_app-store__icons--size"
            src={googlePlayBadge}
            alt="Google Play"
            onClick={() => {
              window.open(
                "https://play.google.com/store/apps/details?id=app.kurr.play",
                "_blank",
                "noreferrer"
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Recipe;
