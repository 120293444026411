import React, { Fragment, useEffect, useState } from "react";

import "./Tags.css";

const Tags = ({tags, setTags, disabled}) => {

	const addTag = event => {
		event.preventDefault();
		if (event.target.value !== "") {
			setTags([...tags, event.target.value]);
			event.target.value = "";
		}
	};

  const removeTag = indexToRemove => {
		setTags([...tags.filter((_, index) => index !== indexToRemove)]);
	};

	const setPlaceholderText = () => {
		if (tags.length < 1) {
			return "Skriv text och tryck enter för att lägga till"
		}
		else if (tags.length < 5) {
			return "Tryck enter för att lägga till";
		}
		else {
			return "";
		}
	}


  return (
		<Fragment>
			<label className="tags-input-label">Taggar</label>
			<div className="tags-input">
				<ul id="tags">
					{tags.map((tag, index) => (
						<li key={index} className="tag">
							<span className='tag-title'>{tag}</span>
							<span className='tag-close-icon'
								onClick={() => removeTag(index)}
							>
								x
							</span>
						</li>
					))}
				</ul>
				<input
					type="text"
					onKeyUp={event => event.key === "Enter" ? addTag(event) : null}
					placeholder={setPlaceholderText()}
					disabled={disabled}
				/>
			</div>
			<p className="tags-input-subheading">Ex. Midsommar, Snabbt, Enkelt... (Max 5)</p>
		</Fragment>
	);
}

export default Tags;