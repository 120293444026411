import { useState, useCallback, useRef, useEffect } from "react";
import CryptoJS from "crypto-js";

export const useHttpClient = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const activeHttpRequests = useRef([]);

  const sendRequest = useCallback(
    async (url, method = "GET", body = null, headers = {}) => {
      setIsLoading(true);
      const httpAbortCtrl = new AbortController();
      activeHttpRequests.current.push(httpAbortCtrl);

      const secretString = process.env.REACT_APP_API_KEY_SECRET;
      const sha = CryptoJS.SHA256(secretString);
      const xsignature = sha.toString(CryptoJS.enc.Hex);

      headers = { ...headers, xsignature };

      // If a token exist in localStorage, add it to the headers
      if (localStorage.getItem("token")) {
        headers = {
          ...headers,
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        };
      }

      try {
        const response = await fetch(url, {
          method,
          body,
          headers,
          signal: httpAbortCtrl.signal,
        });

        const responseData = await response.json();

        activeHttpRequests.current = activeHttpRequests.current.filter(
          (reqCtrl) => reqCtrl !== httpAbortCtrl
        );

        if (!response.ok) {
          // TODO: This is a temporary solution to handle expired tokens
          if (responseData.message === "Token expired.") {
            setError("Sessionen har löpt ut, vänligen logga in igen.");
            setTimeout(() => {
              localStorage.removeItem("token");
              window.location.reload();
            }, 3000);
          } else {
            throw new Error(responseData.message);
          }
        }

        setIsLoading(false);
        return responseData;
      } catch (err) {
        setError(err.message);
        setIsLoading(false);
        throw err;
      }
    },
    []
  );

  const clearError = () => {
    setError(null);
  };

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      activeHttpRequests.current.forEach((abortCtrl) => abortCtrl.abort());
    };
  }, []);

  return { isLoading, error, sendRequest, clearError };
};
