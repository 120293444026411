import React from "react";
import "./TermsAndConditions.css";
import Card from "../../shared/components/UIElements/Card";
import MacaroniPolicyData from "../../shared/assets/MacaroniPolicyData";

const MacaroniPolicy = () => {
  return (
    <Card className="terms-and-conditions-container">
      <h1>Kurrs Makaronpolicy</h1>
      {MacaroniPolicyData.map((term, index) => (
        <div key={index}>
          {term.heading && (
            <h2>
              {term.isIntro ? "" : `${index + 1}. `}
              {term.heading}
            </h2>
          )}
          <ul style={term.isIntro ? { paddingLeft: "0rem" } : null}>
            {term.conditions.map((item, i) => (
              <li key={i}>
                {term.isIntro ? "" : `${index + 1}.${i + 1} `}
                {item}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </Card>
  );
};

export default MacaroniPolicy;
