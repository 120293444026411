const MacaroniPolicyData = [
  {
    heading: "Makaronens kvalitet",
    conditions: [
      "Vår makaron-policy är baserad på 'the squeeze theorem', vilket betyder att vi strävar efter att hålla perfekt spänst i våra makaroner. Vi vill att de ska ha precis rätt mjukhet och hårdhet, för att ge den ultimata smakupplevelsen.",
    ],
  },
  {
    heading: "Diversitet i smaker",
    conditions: [
      "Enligt allmänna relativitetsteorin borde makaroner aldrig vara tråkiga. Därför lovar vi att alltid rekommendera en mängd olika smaker och kombinationer, för att säkerställa att din makaronupplevelse är allt annat än tråkig.",
    ],
  },
  {
    heading: "Kvalitet på såser",
    conditions: [
      "Vi kommer aldrig rekommendera dig en makaron med en dålig sås. Vi lägger alltid extra tid på att välja de bästa ingredienserna för att skapa den perfekta såsen.",
    ],
  },
  {
    heading: "Ingen överkokning",
    conditions: [
      "Vi följer en strikt 'no-overcooking policy'. Vi tror att makaroner bör vara perfekt kokta, inte för mjuka eller för hårda. Vi lovar att aldrig rekommendera dig en makaron som är för mycket eller för lite kokt.",
    ],
  },
  {
    heading: "Makaronens form",
    conditions: [
      "Vi kommer aldrig rekommendera dig en makaron med en dålig form. Vi tror att makaroner bör vara vackra att titta på, precis som de är gudomliga att smaka på. Så om du någonsin ser en makaron med dålig form i vårt flöde, låt oss veta så fixar vi det!",
    ],
  },
];

export default MacaroniPolicyData;
