import React, { Fragment, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { Clear } from "@mui/icons-material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import Card from "../../../shared/components/UIElements/Card";
import { useForm } from "../../../shared/hooks/form-hook";
import RecipeUploadHeader from "../../components/progressBar/RecipeUploadHeader";
import RecipeInstruction from "../../components/RecipeInstruction";
import Button from "../../../shared/components/FormElements/Button";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import ErrorModal from "../../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../../shared/components/UIElements/LoadingSpinner";
import { AuthContext } from "../../../shared/context/auth-context";
import "./Instructions.css";
import { useHistory } from "react-router-dom";
import RecipeSection from "../../components/RecipeSection";
import dbToUploadFormConverter from "../../../shared/util/dbToUploadFormConverter";

const Instructions = () => {
  const auth = useContext(AuthContext);
  const history = useHistory();
  const recipeId = useParams().rid; // gets the recipe-id located in the url
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [activeInstructionId, setActiveInstructionId] = useState(null);
  const [ingredientList, setIngredientList] = useState([]);

  const [formState, inputHandler, setFormData] = useForm(
    {
      instructions: [],
    },
    false
  );

  // Get all ingredients i the database and add these to an array
  useEffect(() => {
    const fetchIngredients = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/api/ingredients/"
        );
        setIngredientList(responseData.ingredients);
      } catch (err) {}
    };

    fetchIngredients();
  }, []);

  // Fetch recipe from database
  useEffect(() => {
    const fetchRecipe = async () => {
      try {
        let responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/api/recipes/${recipeId}`
        );

        responseData.recipe.instructions = dbToUploadFormConverter(
          responseData.recipe.instructions
        );

        if (responseData.recipe.instructions.length === 0) {
          // Call on function that generates a new instruction with a generated id
          //createNewInstructionHandler();
        } else {
          setFormData(
            {
              instructions: responseData.recipe.instructions,
            },
            true,
            "set_instruction"
          );
        }
      } catch (err) {}
    };
    fetchRecipe();
  }, []);

  /**
   * Is called when "create new instruction" button is pressed
   */
  const createNewInstructionHandler = () => {
    // Generate a new uuid
    let newId = uuidv4();

    let newInstruction = {
      id: newId,
      text: {
        value: "",
        isValid: false,
      },
      ingredients: [],
    };

    setFormData(
      {
        instructions: [newInstruction],
      },
      false,
      "set_instruction"
    );

    setActiveInstructionId(newInstruction.id);
  };

  /**
   * Gets called when new instruction is added
   */
  const uploadInstructionsHandler = async (routing) => {
    // Upload the list with instructions to the database
    let instructions = [...formState.inputs.instructions];

    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/api/recipes/upload/instructions",
        "POST",
        JSON.stringify({
          creator: auth.user.id,
          recipeId: recipeId,
          instructions: instructions,
        }),
        { "Content-Type": "application/json" }
      );

      if (routing === "forward") {
        history.push(`/recipe/${recipeId}/step-3`);
      } else if (routing === "back") {
        history.push(`/recipe/${recipeId}/step-1`);
      }
    } catch (err) {}
  };

  /**
   * Gets called when "new instruction" button is pressed
   */
  const addInstructionHandler = () => {
    uploadInstructionsHandler();
    createNewInstructionHandler();
  };

  /**
   * Gets called when "new section" button is pressed
   */
  // TODO:
  const addSectionHandler = () => {
    uploadInstructionsHandler();
    createNewSection();
  };

  /**
   * Creates a new section and inserts into state
   */
  const createNewSection = () => {
    let newId = uuidv4();

    let newSection = {
      id: newId,
      section: {
        value: "",
        isValid: false,
      },
    };

    // Should be same code as instruction?
    setFormData(
      {
        instructions: [newSection],
      },
      false,
      "set_instruction"
    );

    setActiveInstructionId(newSection.id);
  };

  /**
   * Gets called when a user delets a step
   */
  const deleteInstructionHandler = (instructionId) => {
    //Remove the instruction from the state
    setFormData({}, true, "delete_instruction", instructionId);
    // Call on function to update state in database
    uploadInstructionsHandler();
    setActiveInstructionId(null);
  };

  /**
   * Gets called when the bottom "next step" button is called
   */
  const submitInstructionsHandler = () => {
    uploadInstructionsHandler("forward");
  };

  /**
   * Gets called when a user clicks back button
   */
  const stepBackHandler = () => {
    uploadInstructionsHandler("back");
  };

  const handleOnDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    //Update the formState  with the new order of the instructions
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    } else {
      let newState = { ...formState };
      // Pop the instruction to move
      const [instructionToMove] = newState.inputs.instructions.splice(
        source.index,
        1
      );
      // Insert instruction at new index
      newState.inputs.instructions.splice(
        destination.index,
        0,
        instructionToMove
      );

      setFormData(
        {
          instructions: newState.inputs.instructions,
        },
        true,
        "move_instruction"
      );

      uploadInstructionsHandler();
    }
  };

  return (
    <Fragment>
      <ErrorModal error={error} onClear={clearError} />

      {!isLoading ? (
        <Card className="instructions">
          <RecipeUploadHeader
            currentStep={2}
            title="Instruktioner steg-för-steg"
            onBack={stepBackHandler}
            onClick={uploadInstructionsHandler}
          />

          <form onSubmit={uploadInstructionsHandler}>
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="instructions">
                {(provided) => (
                  <ul {...provided.droppableProps} ref={provided.innerRef}>
                    {formState.inputs.instructions.map((instruction, index) => {
                      return (
                        <Draggable
                          key={instruction.id}
                          draggableId={instruction.id}
                          index={index}
                        >
                          {(provided) => (
                            <li
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                            >
                              {instruction.section ? (
                                <RecipeSection
                                  value={instruction.section.value}
                                  formState={formState}
                                  setFormData={setFormData}
                                  inputHandler={inputHandler}
                                  instructionId={instruction.id}
                                  deleteInstructionHandler={
                                    deleteInstructionHandler
                                  } /* Inte dubbelkollat om detta bör modifieras i form-hook */
                                  activeInstructionId={activeInstructionId}
                                  setActiveInstructionId={
                                    setActiveInstructionId
                                  }
                                />
                              ) : (
                                <RecipeInstruction
                                  value={instruction.text.value}
                                  formState={formState}
                                  inputHandler={inputHandler}
                                  instructionId={instruction.id}
                                  ingredientsList={instruction.ingredients}
                                  setIngredientsList={setIngredientList}
                                  fullIngredientList={ingredientList}
                                  setFormData={setFormData}
                                  userId={auth.user.id}
                                  uploadInstructionsHandler={
                                    uploadInstructionsHandler
                                  }
                                  deleteInstructionHandler={
                                    deleteInstructionHandler
                                  }
                                  activeInstructionId={activeInstructionId}
                                  setActiveInstructionId={
                                    setActiveInstructionId
                                  }
                                  index={index}
                                />
                              )}
                            </li>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>

            {activeInstructionId === null && (
              <div className="add-buttons-container">
                <button
                  onClick={addInstructionHandler}
                  type="submit"
                  className="button-add-ingredient"
                  disabled={
                    !formState.isValid &&
                    formState.inputs.instructions.length !== 0
                  }
                >
                  Lägg till steg
                </button>
                <button
                  onClick={addSectionHandler}
                  type="submit"
                  className="button-add-ingredient"
                  disabled={
                    !formState.isValid &&
                    formState.inputs.instructions.length !== 0
                  }
                >
                  Lägg till rubrik
                </button>
              </div>
            )}
          </form>

          <div className="button-bottom">
            <Button
              onClick={submitInstructionsHandler}
              type="submit"
              center
              disabled={!formState.isValid}
            >
              Nästa
            </Button>
          </div>
          <Button type="button" onClick={stepBackHandler} center inverse>
            Bakåt
          </Button>
        </Card>
      ) : (
        <Card>
          <LoadingSpinner asOverlay />
        </Card>
      )}
    </Fragment>
  );
};

export default Instructions;
