const TermsAndConditionsData = [
  {
    isIntro: true,
    heading: "",
    conditions: [
      "Välkommen till Kurr! Vi är ett techbolag som arbetar för att göra hållbara middagsval enklare för dig och din familj genom att erbjuda personliga receptförslag baserade på dina preferenser och behov. Genom att använda våra tjänster, godkänner du att följa dessa användarvillkor och vår integritetspolicy, som reglerar din användning av Kurr och dess funktioner.",
      "Det är viktigt att du läser igenom dessa användarvillkor noggrant innan du använder vår plattform eller registrerar dig för våra tjänster. Dessa villkor reglerar ditt användande av vår webbplats, mobilapplikation och alla relaterade tjänster. Om du inte godkänner dessa villkor, ber vi dig att avsluta din användning av vår plattform och våra tjänster omedelbart.",
      "Vi strävar efter att skapa en positiv upplevelse för våra användare, och därför är det viktigt att du förstår vad som krävs av dig som användare, vilka regler och begränsningar som gäller och vad du kan förvänta dig av oss. Tack för att du valt Kurr, och vi hoppas att du kommer att uppskatta våra tjänster lika mycket som vi gör.",
      "Villkoren uppdaterades 29 Juni 2023.",
    ],
  },
  {
    heading: "Tillämpning och acceptans",
    conditions: [
      'Dessa användarvillkor ("Villkoren") gäller för din användning av den digitala tjänsten Kurr ("Tjänsten") som tillhandahålls av Kurr AB, ett bolag registrerat i Sverige med organisationsnummer 559345-2922 ("Kurr", "vi", "oss" eller "vårt").',
      "Genom att skapa ett konto och använda Tjänsten, godkänner du dessa Villkor. Om du inte godkänner Villkoren, ska du inte använda Tjänsten. Om du bryter mot dessa Villkor kan vi, efter eget gottfinnande, avsluta ditt konto eller begränsa din tillgång till Tjänsten.",
    ],
  },
  {
    heading: "Tillgång till tjänsten",
    conditions: [
      "För att få tillgång till och använda Tjänsten måste du skapa ett konto. Du garanterar att all information du tillhandahåller vid skapandet av ditt konto är korrekt och fullständig.",
      "Tjänsten är endast tillgänglig för användare som är minst 13 år gamla. Genom att skapa ett konto och använda Tjänsten, bekräftar du att du är minst 13 år.",
      "Du är ansvarig för säkerheten och konfidentialiteten för ditt lösenord och konto. Du är ansvarig för all användning och aktivitet på ditt konto, inklusive användning av kontot av andra. Du får inte dela ditt konto eller lösenord med någon annan.",
      "Kurr kan göra ändringar i Tjänsten, inklusive att ta bort eller lägga till funktioner, utan förvarning.",
    ],
  },
  {
    heading: "Användning av tjänsten",
    conditions: [
      "Kurr erbjuder Tjänsten som en plattform för att skapa och dela matlagning och måltidsplaneringsinnehåll. Vi ger ingen medicinsk rådgivning eller garanterar några hälsoeffekter.",
      "Vi ger CO2e-data för våra recept men kan inte garantera att dessa är 100% korrekta för alla recept.",
      "Vi ansvarar inte för allergiska reaktioner eller andra hälsoeffekter av matlagning baserat på vårt innehåll.",
      "Du får inte använda Tjänsten för olämpliga, olagliga eller obehöriga ändamål. Vid överträdelse av dessa villkor kan ditt konto stängas ner.",
    ],
  },
  {
    heading: "Intellektuell egendom",
    conditions: [
      "Allt innehåll och material på Tjänsten, inklusive, men inte begränsat till, text, grafik, logotyper, bilder, ljudklipp, digitala nedladdningar och programvara, ägs av Kurr eller dess innehållsleverantörer och skyddas av internationella upphovsrättslagar.",
      "Du får inte kopiera, reproducera, modifiera, publicera, överföra, sälja eller på annat sätt utnyttja något innehåll som finns på Tjänsten utan skriftligt tillstånd från Kurr.",
    ],
  },
  {
    heading: "Avslutande av konto",
    conditions: [
      "Du kan när som helst avsluta ditt konto genom att skicka ett meddelande till oss via e-post på macaroni@kurr.co. Du kan också avsluta ditt konto via inställningsmenyn i appen.",
    ],
  },
  {
    heading: "Personuppgifter och integritet",
    conditions: [
      "Vi samlar in och behandlar dina personuppgifter i enlighet med vår Integritetspolicy, som finns tillgänglig på: https://kurr.co/privacy-policy",
    ],
  },
  {
    heading: "Anvarsbegränsning och friskrivning",
    conditions: [
      'Kurr tillhandahåller Tjänsten "som den är" och "som tillgänglig". Vi frånsäger oss alla garantier, uttryckliga eller underförstådda, inklusive men inte begränsat till underförstådda garantier om säljbarhet, lämplighet för ett visst ändamål och icke-överträdelse.',
      "Kurr ansvarar inte för direkta eller indirekta skador som uppstår på grund av användning eller oförmåga att använda Tjänsten. Kurr är inte ansvarig för tekniska eller prissättningsfel i tjänsten. Kurr är inte ansvarig för misslyckande att uppfylla sina åtaganden på grund av omständigheter utanför sin kontroll.",
      "Vi är ständigt i utvecklingsfas och det kan förekomma buggar i Tjänsten. Vi arbetar kontinuerligt för att uppdatera dessa buggar och skapa nya funktioner, men vi är inte ansvariga för eventuella problem eller förluster som kan uppstå på grund av dessa buggar.",
    ],
  },
  {
    heading: "Ändringar av villkoren",
    conditions: [
      "Vi kan ändra dessa Villkor från tid till annan. Om vi gör väsentliga ändringar i dessa Villkor, kommer vi att meddela dig antingen genom att skicka ett meddelande till den e-postadress du angav när du registrerade dig eller genom att publicera en notis på Tjänsten.",
    ],
  },
  {
    heading: "Tvistlösning",
    conditions: [
      "Dessa Villkor ska tolkas och tillämpas i enlighet med svensk lag. Tvister som uppstår i anslutning till dessa Villkor ska lösas av svenska domstolar.",
    ],
  },
  {
    heading: "Kontaktinformation",
    conditions: [
      "Om du har frågor om dessa användarvillkor, kontakta oss på: macaroni@kurr.co",
    ],
  },
];

export default TermsAndConditionsData;
